import { useState } from "react";
import { useSelector } from "react-redux";
import Currency from 'react-currency-formatter';
import { useNavigate, useParams } from "react-router-dom";
import { confirmOrder, placeOrder } from "../../api/productsApi";
import { toast } from "react-toastify";

const OrderSummary = ({ selectedAddress, showCheckoutButton, showPayButton, showConfirmOrderButton }) => {

    const navigate = useNavigate();

    const { slug } = useParams();

    const shop = useSelector((state) => state?.shop);
    const order = useSelector((state) => state?.order);

    const [currency] = useState(shop?.currency_abbreviation || 'INR');

    const submitPlaceOder = () => {
        placeOrder(order?.productId, order?.quantity, selectedAddress, shop?.uuid).then(response => {
            if (response.status === 200) {
                toast.success(response.message);
                window.location.href = response.data;
                //openJsCheckoutPopup(response.data.orderid, response.data.token, response.data.amount);               
            } else {
                toast.error(response.message);
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    // const openJsCheckoutPopup = (orderId, txnToken, amount) => {
    //     var config = {
    //         "root": "",
    //         "flow": "DEFAULT",
    //         "data": {
    //             "orderId": orderId,
    //             "token": txnToken,
    //             "tokenType": "TXN_TOKEN",
    //             "amount": amount
    //         },
    //         "merchant": {
    //             "redirect": true
    //         },
    //         "handler": {
    //             "notifyMerchant": function (eventName, data) {
    //                 console.log("notifyMerchant handler function called");
    //                 console.log("eventName => ", eventName);
    //                 console.log("data => ", data);
    //             }
    //         }
    //     };
    //     if (window.Paytm && window.Paytm.CheckoutJS) {
    //         window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
    //             window.Paytm.CheckoutJS.invoke();
    //         }).catch(function onError(error) {
    //             console.log("error => ", error);
    //         });
    //     }
    // }

    return (
        <div className="card mb-6 mt-lg-14">
            <div className="card-body">
                <h2 className="h4 mb-4">Order Summary</h2>
                <div className="card mb-2">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div>Gross Amount</div>
                            </div>
                            <span>
                                {order?.discountAmount > 0 ?
                                    <del>
                                        <Currency pattern="!##,##,###." quantity={order?.totalGross} currency={currency} />
                                    </del>
                                    :
                                    <Currency pattern="!##,##,###." quantity={order?.totalGross} currency={currency} />
                                }
                            </span>
                        </li>

                        {order?.discountAmount > 0 ? <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div>Offer Price</div>
                            </div>
                            <span className="text-success">
                                <Currency pattern="!##,##,###." quantity={order?.totalGross - order?.discountAmount} currency={currency} />
                            </span>
                        </li> : null}

                        {order?.quantity > 0 ? <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div>Quantity</div>
                            </div>
                            <span>
                                <p className="m-0">x{order?.quantity}</p>
                            </span>
                        </li> : null}

                        {order?.deliveryCharges > 0 ? <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div>Delivery Charges</div>
                            </div>
                            <span className="text-danger">
                                <Currency pattern="!##,##,###." quantity={order?.deliveryCharges} currency={currency} />
                            </span>
                        </li> : null}

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div className="fw-bold">Net Amount</div>
                            </div>
                            <span className="fw-bold">
                                <Currency pattern="!##,##,###." quantity={order?.unitTotalAmount || order?.netAmount} currency={currency} />
                            </span>
                        </li>

                        {order?.gst > 0 ? <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div>GST</div>
                            </div>
                            <span className="text-danger">
                                <Currency quantity={order?.gst} currency={currency} />
                            </span>
                        </li> : null}

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="me-auto">
                                <div className="fw-bold">Total Amount</div>
                            </div>
                            <span className="fw-bold">
                                <Currency quantity={order?.totalAmount} currency={currency} />
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="d-grid mb-1 mt-4">
                    {showCheckoutButton && <button disabled={!shop?.is_shop_open} className="btn btn-primary btn-lg" onClick={() => {
                        navigate(slug ? `/${slug}/checkout` : '/checkout');
                    }} type="button">Proceed to Checkout</button>}

                    {showPayButton && selectedAddress && <button disabled={!shop?.is_shop_open} className="btn btn-primary btn-lg d-flex justify-content-between align-items-center" onClick={submitPlaceOder} type="button">
                        Pay <span className="fw-bold">
                            <Currency quantity={order?.totalAmount} currency={currency} />
                        </span>
                    </button>}

                    {showConfirmOrderButton && selectedAddress && <button disabled={!shop?.is_shop_open} className="btn btn-primary btn-lg align-items-center" onClick={submitPlaceOder} type="button">
                        Place Order
                    </button>}
                </div>
                <p className="m-0"><small>By placing your order, you agree to be bound by the Shoppers Hunt <a href="#!">Terms of Service</a> and <a href="#!">Privacy Policy.</a> </small></p>
            </div>
        </div>
    )
}

export default OrderSummary;