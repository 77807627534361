import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderView } from "../../../../redux/actions/settingActions";
import { fetchNearByShops } from "../../../../api/marketPlaceApi";
import { setNearByShops } from "../../../../redux/actions/marketPlaceActions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const NearByStores = () => {

    const dispatch = useDispatch();

    const nearByShops = useSelector((state) => state?.nearByShops);
    const latitude = useSelector((state) => state?.latitude);
    const longitude = useSelector((state) => state?.longitude);
    const pin = useSelector((state) => state?.pin);

    useEffect(() => {
        dispatch(setLoaderView(true));
        fetchNearByShops(latitude, longitude).then((response) => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setNearByShops(response.data));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }, [dispatch, pin, latitude, longitude]);

    return (
        <section className="mt-lg-14 mt-8">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10 col-12">
                        <div className="row align-items-center mb-4">

                            <div class="col-md-8 col-12">
                                <div class="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" class="bi bi-shop text-primary" viewBox="0 0 16 16">
                                            <path
                                                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                                        </svg>
                                    </div>
                                    <div class="ms-3">
                                        <h3 class=" mb-0">Best stores in Locations</h3>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-end col-12 d-none d-md-block">
                                <a href="#">
                                    All stores
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-right">
                                        <polyline points="9 18 15 12 9 6"></polyline>
                                    </svg>
                                </a>
                            </div>
                        </div>

                        <div class="row row-cols-2 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 g-4 g-lg-4">

                            {nearByShops?.map((shop) => {
                                return (<div class="col">
                                    <Link to={`${shop?.slug}`} class="card p-6 cursor-pointer">
                                        <div>
                                            {shop?.logo ? <img src={process.env.REACT_APP_API_URL + '/media' + shop?.logo} alt="" class="rounded-circle object-fit-contain icon-shape icon-xl" /> :
                                                <img src="../assets/images/stores-logo/stores-logo-1.svg" alt="" class="rounded-circle object-fit-contain icon-shape icon-xl" />}
                                        </div>
                                        <div class="mt-4">
                                            <h2 class="mb-1 h5 text-primary">{shop?.name}</h2>
                                            <div class="small text-muted"><span class="me-2">{shop?.category_name}</span></div>
                                            {/* <div class="py-3">
                                                <ul class="list-unstyled mb-0 small">
                                                    <li>Delivery
                                                    </li>
                                                    <li>Pickup available</li>
                                                </ul>
                                            </div> */}
                                            <div>
                                                <div class="badge text-bg-light border my-1">{shop?.distance} Km away</div>
                                                <div class={`badge text-bg-${shop?.is_open ? 'success' : 'danger'} border mx-1`}> {shop?.is_open ? 'Open' : 'Closed'} </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NearByStores;