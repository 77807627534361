import Select from 'react-select';
import Label from './Label';

const SearchDropDown = ({ label, value, name, options, required, labelClassName, onChange }) => {

    return (
        <>
            <Label labelClassName={labelClassName} label={label} required={required} />
            <Select
                // menuPosition='fixed'
                menuPlacement='auto'
                name={name}
                options={options}
                value={options?.filter((obj) => {
                    return obj?.value === value
                })}
                onChange={onChange} />
        </>
    );
}

export default SearchDropDown;