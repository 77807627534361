import Carousel, { Dots } from '@brainhubeu/react-carousel';
import { useEffect, useState } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
// import InnerImageZoom from 'react-inner-image-zoom';

const ImageSlider = ({ productImages }) => {

    const [carouselValue, setCarouselValue] = useState(0);
    const [topImages, setTopImages] = useState([]);
    const [bottomImages, setBottomImages] = useState([]);

    useEffect(() => {
        if (productImages) {
            setTopImages(productImages?.map((image, index) => {
                return <img onClick={()=>setCarouselValue(index)} className='product-slider-image' hideHint={true} src={image?.image_url || "/assets/imgs/no_product_image.png"} />
            }));

            setBottomImages(productImages?.map((image) => {
                return (<img src={image?.image_url || "/assets/imgs/no_product_image.png"} alt="" />)
            }));
        }
    }, [productImages]);

    return (
        <>
            {topImages.length > 0 ?
                <>
                    <Carousel onChange={(value) => setCarouselValue(value)}
                        value={carouselValue}
                        slides={topImages}
                        plugins={['arrows']} />

                    <Dots onChange={(value) => setCarouselValue(value)}
                        value={carouselValue}
                        number={bottomImages?.length}
                        thumbnails={bottomImages} />
                </>
                :
                <img src="/assets/topImages/no_product_image.png" alt="" className="w-100" />
            }
        </>
    )
}

export default ImageSlider;