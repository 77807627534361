const CallToAction = () => {

    return (
        <section class="my-lg-14 my-8">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10 col-12">
                        <div className="row align-items-center mb-4">
                            <div class="col-12 mb-3 mb-lg-0">
                                <div>
                                    <div class="py-10 px-8 rounded"
                                        style={{ background: "url(assets/images/banner/grocery-banner.png)no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                                        <div>
                                            <h3 class="fw-bold mb-1">Want to get your shop online?</h3>
                                            <p class="mb-4">Showcase your products to your customers online for free!</p>
                                            <a href="#!" class="btn btn-dark">Start Now!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction;