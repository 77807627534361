import PinPicker from "../PinPicker";

const PinPickerPopup = () => {

    return (
        <div className="modal fade" id="locationModal" tabIndex="-1" aria-labelledby="locationModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <PinPicker />
                </div>
            </div>
        </div>
    );
}

export default PinPickerPopup;