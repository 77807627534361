import axios from "axios";
import { logoutUser } from "../redux/actions/userActions";
import store from "../redux/store";

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

apiService.interceptors.request.use((config) => {
    const state = store?.getState();
    const token = state?.user?.token;
    config.headers.Authorization = token ? `Token ${token}` : '';
    return config;
});

apiService.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store?.dispatch(logoutUser());
        window.location = '/auth/login';
    }
    return Promise.reject(error);
});

export default apiService;