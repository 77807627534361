import { ActionTypes } from "../constants/actionTypes"

export const userReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USER:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

export const cartReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CART:
            return payload;
        default:
            return state;
    }
}

export const wishlistReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_WISHLIST:
            return payload;
        default:
            return state;
    }
}