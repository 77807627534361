const ReviewSummary = ({ productName, reviews }) => {

    return (
        <div className="card mb-6 mt-lg-14">
            <div className="card-body p-6">
                <h4 className="mb-3">Customer reviews</h4>
                <div className="card mb-6">
                    <div className="card-body p-6">
                        <h3 className="mb-3">{productName}</h3>
                        <span>
                            <small className="text-warning">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-half"></i>
                            </small>
                            <span className="ms-3">{reviews?.average_rating} out of 5</span>
                            <small className="ms-3">({reviews?.count_rating} global ratings)</small>
                        </span>

                        
                        <div className="d-flex align-items-center mb-2 mt-3">
                            <div className="text-nowrap me-3 text-muted">
                                <span className="d-inline-block align-middle text-muted">5</span>
                                <i className="bi bi-star-fill ms-1 small text-warning"></i>
                            </div>
                            <div className="w-100">
                                <div className="progress progress-bar-height">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${reviews?.rating['1'] * 20}%` }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div className="text-nowrap me-3 text-muted">
                                <span className="d-inline-block align-middle text-muted">4</span>
                                <i className="bi bi-star-fill ms-1 small text-warning"></i>
                            </div>
                            <div className="w-100">
                                <div className="progress progress-bar-height">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${reviews?.rating['2'] * 20}%` }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div className="text-nowrap me-3 text-muted">
                                <span className="d-inline-block align-middle text-muted">3</span>
                                <i className="bi bi-star-fill ms-1 small text-warning"></i>
                            </div>
                            <div className="w-100">
                                <div className="progress progress-bar-height">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${reviews?.rating['3'] * 20}%` }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div className="text-nowrap me-3 text-muted">
                                <span className="d-inline-block align-middle text-muted">2</span>
                                <i className="bi bi-star-fill ms-1 small text-warning"></i>
                            </div>
                            <div className="w-100">
                                <div className="progress progress-bar-height">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${reviews?.rating['4'] * 20}%` }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div className="text-nowrap me-3 text-muted">
                                <span className="d-inline-block align-middle text-muted">1</span>
                                <i className="bi bi-star-fill ms-1 small text-warning"></i>
                            </div>
                            <div className="w-100">
                                <div className="progress progress-bar-height">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${reviews?.rating['5'] * 20}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-grid">
                    <h4>Review this product</h4>
                    <p className="mb-0">Share your thoughts with other customers.</p>
                    <a href="#" className="btn btn-outline-gray-400 mt-4 text-muted">Write the Review</a>
                </div>

            </div>
        </div>
    )
}

export default ReviewSummary;