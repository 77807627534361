import AddressPicker from '../Common/AddressPicker';

const AddAddressPopup = ({ submitAddNewAddress }) => {

    return (
        <div className="modal fade" id="addAddressModal" tabIndex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body p-6">
                        <div className="d-flex justify-content-between mb-5">
                            <div>
                                <h5 className="mb-1" id="addAddressModalLabel">New Shipping Address</h5>
                                <p className="small mb-0">Add new shipping address for your order delivery.</p>
                            </div>
                            <div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <form onSubmit={submitAddNewAddress} id="addAddressForm">
                            <AddressPicker />
                            <div className="col-12 text-end">
                                <button type="button" id="addAddressModalCloseButton" className="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary ms-2" type="submit">Save Address</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddAddressPopup;