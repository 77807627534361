import { getMyOrders } from "../../api/settingsApi";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { setLoaderView } from "../../redux/actions/settingActions";
import Currency from 'react-currency-formatter';
import { useDispatch, useSelector } from "react-redux";
import { formatOrderStatus } from "../../helper/PaymentDetails/paymentDetailsFormatter";
import { dateFormat } from "../../helper/DateTime/dateTimeFormatter";
import CenterErrorMessage from "../Common/CenterErrorMessage";
import { Link } from "react-router-dom";

const MyOrders = () => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const [currency] = useState(shop?.currency_abbreviation || 'INR');
    const [orders, setOrders] = useState([]);
    const [showNoOrdersMessage, setShowNoOrdersMessage] = useState(null);

    useEffect(() => {
        dispatch(setLoaderView(true));
        getMyOrders().then(response => {
            setShowNoOrdersMessage(response.data.length === 0 ? true : false);
            setOrders(response.data);
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message);
        });
    }, []);

    return (
        <>
            {showNoOrdersMessage === true && <CenterErrorMessage
                title="Empty No More"
                description="An empty orders page is like a brand new day. Fill it up with amazing stuff and make it a memorable one"
                button="Explore our Products"
                path="/" />
            }

            {showNoOrdersMessage === false && <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="py-6 p-md-6 p-lg-6">
                                    <div className="d-flex justify-content-between mb-6">
                                        <h2 className="mb-0">My Orders</h2>
                                    </div>
                                    <div className="row">
                                        {orders?.map((order) => {
                                            return (
                                                <div className="container mb-6">
                                                    <div className="row justify-content-center">
                                                        <div className="card col-12 p-6">
                                                            <div className="row g-4 orders-container">
                                                                <div className="col-6 col-sm-6 order-item-first col-md-3">
                                                                    <h6 className="mb-1">Shipping Address</h6>
                                                                    <p className="m-0">{order?.address_details?.full_name}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 order-item col-md-2">
                                                                    <h6 className="mb-1">Order Placed On</h6>
                                                                    <p className="m-0">{dateFormat(order?.payment_details?.created_on)}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 order-item col-md-2">
                                                                    <h6 className="mb-1">Order Status</h6>
                                                                    <p className="m-0">{formatOrderStatus(order?.payment_details?.order_status)}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 order-item col-md-2">
                                                                    <h6 className="mb-1">Invoice</h6>
                                                                    <p className="m-0">View</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 order-item col-md-2">
                                                                    <h6 className="mb-1">Paid Amount</h6>
                                                                    <Currency pattern="!##,##,###." quantity={order?.total_amount} currency={currency} />
                                                                </div>
                                                                <div className="col-6 col-sm-6 order-item-second col-md-1">
                                                                    <div className="dropdown text-right">
                                                                        <div className="text-reset cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="feather-icon icon-more-vertical fs-4"></i>
                                                                        </div>
                                                                        <ul className="dropdown-menu">
                                                                            <li><a className="dropdown-item" href="#"><i className="bi bi-trash me-3"></i>View Details</a></li>
                                                                            <li><a className="dropdown-item" href="#"><i className="bi bi-pencil-square me-3 "></i>Reorder</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {order?.shop_order_data?.map((shopOrder) => {
                                                                return <div className="container mt-6">
                                                                    <div className="row justify-content-center">
                                                                        <div className="card bg-light col-12 p-6">
                                                                            <div className="d-flex justify-content-between">
                                                                                <b className="text-primary m-0">{shopOrder?.shop_details.name}</b>
                                                                                <div>
                                                                                    <span className="m-0">{shopOrder?.shop_details?.building_number} </span>
                                                                                    <span className="m-0">{shopOrder?.shop_details?.locality} </span>
                                                                                    <span className="m-0">{shopOrder?.shop_details?.city_name} </span>
                                                                                    <span className="m-0">{shopOrder?.shop_details?.pincode}</span>
                                                                                </div>
                                                                            </div>
                                                                            {shopOrder?.order_items?.map((orderItem) => {
                                                                                return (
                                                                                    <div className="container mt-6">
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="card col-12 p-6">
                                                                                                <div className="row g-4">
                                                                                                    <div className="col-4 col-sm-4 col-md-1">
                                                                                                        <img src={orderItem?.product_details.image_list[0].image_url || `/assets/images/no_product_image.png`} alt="" className="order-summary-image border rounded" />
                                                                                                    </div>
                                                                                                    <div className="d-sm-block d-md-none col-8 col-sm-8">
                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div>Item Amount</div>
                                                                                                            </div>
                                                                                                            <span>
                                                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.net_amount} currency={currency} />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div>Quantity</div>
                                                                                                            </div>
                                                                                                            <span>{orderItem?.quantity}</span>
                                                                                                        </div>
                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div className="fw-bold">Total Amount</div>
                                                                                                            </div>
                                                                                                            <span className="fw-bold">
                                                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.unit_total_amount} currency={currency} />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-12 col-sm-12 col-md-8">
                                                                                                        <div className="mt-md-0 ms-md-6">
                                                                                                            <h3 className="mb-1">{orderItem?.product_details.variant_name || orderItem?.product_details?.name}</h3>
                                                                                                            <div>
                                                                                                                <small className="text-warning">
                                                                                                                    <i className="bi bi-star-fill"></i>
                                                                                                                    <i className="bi bi-star-fill"></i>
                                                                                                                    <i className="bi bi-star-fill"></i>
                                                                                                                    <i className="bi bi-star-fill"></i>
                                                                                                                    <i className="bi bi-star-half"></i>
                                                                                                                </small>
                                                                                                                <Link to="/" className="ms-2">(30 reviews)</Link>
                                                                                                            </div>
                                                                                                            {orderItem?.discount_amount ? <div className="product-price float-left m-0">
                                                                                                                <span className="current-price-small text-primary">
                                                                                                                    <Currency pattern="!##,##,###." quantity={orderItem?.net_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    <span className="save-price-small text-warning">{orderItem?.discount_percentage}% Off</span>
                                                                                                                    <span className="old-price-small text-secondary">
                                                                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                                :
                                                                                                                <div className="product-price text-primary float-left m-0">
                                                                                                                    <span className="current-price-small text-brand">
                                                                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {orderItem?.product_details?.variant_size && <div className="d-flex">
                                                                                                                <strong className="me-2">Size: </strong>
                                                                                                                <p className="m-0">{orderItem?.product_details?.variant_size}</p>
                                                                                                            </div>}
                                                                                                            {orderItem?.product_details?.variant_color && <div className="d-flex">
                                                                                                                <strong className="me-2">Color:</strong>
                                                                                                                <div className="color-available" style={{ backgroundColor: orderItem?.product_details?.variant_color }} />
                                                                                                            </div>}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="d-none d-md-block col-md-3">
                                                                                                        <h6 className="mb-1">Item Summary</h6>

                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div>Price</div>
                                                                                                            </div>
                                                                                                            <span>
                                                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.net_amount} currency={currency} />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div>Quantity</div>
                                                                                                            </div>
                                                                                                            <span>{orderItem?.quantity}</span>
                                                                                                        </div>
                                                                                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div className="me-auto">
                                                                                                                <div className="fw-bold">Total</div>
                                                                                                            </div>
                                                                                                            <span className="fw-bold">
                                                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.unit_total_amount} currency={currency} />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>}
        </>
    )
}

export default MyOrders;