import CallToAction from "./MarketPlaceSections/CallToAction";
import Carousel from "./MarketPlaceSections/Carousel";
import Categories from "./MarketPlaceSections/Categories";
import Features from "./MarketPlaceSections/Features";
import GetMobileApp from "./MarketPlaceSections/GetMobileApp";
// import LocationPicker from "./MarketPlaceSections/LocationPicker";
import NearByStores from "./MarketPlaceSections/NearByStores";
// import Stats from "./MarketPlaceSections/Stats";

const MarketPlace = () => {

    return (
        <main>
            {/* <LocationPicker /> */}
            <Carousel banners={[
                {
                    "name": "Top Banner",
                    "id": 20,
                    "title": "",
                    "description": "",
                    "created_on": "2023-11-06T19:09:11.375308+00:00",
                    "modified_on": "2023-11-24T16:41:40.629379+00:00",
                    "is_active": true,
                    "created_by_id": 7,
                    "modified_by_id": 7,
                    "shop_id_id": 27,
                    "banner_type_id": 1,
                    "file_url": "https://api.shoppershunt.com/media/shop_27/banner_images/Banner_2_Web----lid-fragrance--20_qfDYCTD.webp"
                },
                {
                    "name": "Top Banner",
                    "id": 16,
                    "title": "",
                    "description": "",
                    "created_on": "2023-11-06T19:05:36.62948+00:00",
                    "modified_on": "2023-11-06T19:09:26.626878+00:00",
                    "is_active": true,
                    "created_by_id": 7,
                    "modified_by_id": 7,
                    "shop_id_id": 27,
                    "banner_type_id": 1,
                    "file_url": "https://api.shoppershunt.com/media/shop_27/banner_images/Static-1840x600--Web_261023.webp"
                },
                {
                    "name": "Top Banner",
                    "id": 19,
                    "title": "",
                    "description": "",
                    "created_on": "2023-11-06T19:08:29.275626+00:00",
                    "modified_on": "2023-11-06T19:08:29.275724+00:00",
                    "is_active": true,
                    "created_by_id": 7,
                    "modified_by_id": 7,
                    "shop_id_id": 27,
                    "banner_type_id": 1,
                    "file_url": "https://api.shoppershunt.com/media/shop_27/banner_images/tommy-hilfiger-web--2023--20-10-n_w0DixtV.webp"
                },
                {
                    "name": "Top Banner",
                    "id": 18,
                    "title": "",
                    "description": "",
                    "created_on": "2023-11-06T19:07:14.876799+00:00",
                    "modified_on": "2023-11-06T19:07:14.876901+00:00",
                    "is_active": true,
                    "created_by_id": 7,
                    "modified_by_id": 7,
                    "shop_id_id": 27,
                    "banner_type_id": 1,
                    "file_url": "https://api.shoppershunt.com/media/shop_27/banner_images/Couple-static-web-600_AW23.jpg"
                }
            ]} />
            <Categories />
            <NearByStores />
            <CallToAction />
            <Features />
            {/* <Stats /> */}
            <GetMobileApp />
        </main>
    );
}

export default MarketPlace;