import apiService from "./api";
import securedApiService from "./securedApi";

export const fetchProducts = async (shopId) => {
    const response = await apiService.get(`shop_products/get_products?shop_id=${shopId}&page_count=20&page_no=1`);
    return response?.data;
}

export const fetchProduct = async (productId, shopId) => {
    const response = await apiService.get(`shop_products/get_single_product/${productId}?shop_id=${shopId}`);
    return response?.data;
}

// export const placeOrder = async (item_id, quantity, address_id, shopId) => {
//     const response = await securedApiService.post(`shop_orders/place_order?shop_id=${shopId}`, { item_id, address_id, quantity, 'payment_method': 'ONLINE' });
//     return response?.data;
// }

export const placeOrder = async (item_id, quantity, address_id, shopId) => {
    const response = await securedApiService.post(`shop_orders/create-checkout-session?shop_id=${shopId}`, { item_id, address_id, quantity, 'payment_method': 'ONLINE' });
    return response?.data;
}

export const confirmOrder = async (order_id) => {
    const response = await securedApiService.post(`shop_orders/confirm_order`, { ORDERID: order_id });
    return response?.data;
}

export const getOrderDetails = async (order_id) => {
    const response = await securedApiService.get(`shop_orders/single_order/${order_id}`);
    return response?.data;
}