// import FullScreenLoader from "../../Components/Common/FullScreenLoader";
import { Outlet } from 'react-router-dom';
import Header from "../../Components/Common/Header/Header";
import Footer from "../../Components/Common/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { getBanners, getShopConfiguration } from '../../api/settingsApi';
import { setBanners, setLatitude, setLoaderView, setLongitude, setPIN, setShopDetail } from '../../redux/actions/settingActions';
import { setProducts } from '../../redux/actions/productActions';
import { useEffect } from 'react';
import Geocode from "react-geocode";
import { toast } from 'react-toastify';
import TopMessage from '../../Components/Common/Header/TopMessage';

const BaseLayout = () => {

    const shop = useSelector((state) => state?.shop);
    const pin = useSelector((state) => state?.pin);
    const latitude = useSelector((state) => state?.latitude);
    const longitude = useSelector((state) => state?.longitude);

    const dispatch = useDispatch();

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

    useEffect(() => {
        const shopSocket = new WebSocket(
            'wss://'
            + process.env.REACT_APP_API_HOST
            + '/ws/update_shop_status/'
            + shop?.uuid
            + '_updated'
        );

        const bannerSocket = new WebSocket(
            'wss://'
            + process.env.REACT_APP_API_HOST
            + '/ws/update_shop_banner/'
            + shop?.uuid
            + '_update_banner'
        );

        shopSocket.onmessage = function (response) {
            console.log("Shop Socket response received - ", response);
            if (shop) {
                getShopConfiguration(shop?.slug).then((response) => {
                    if (response.status === 200) {
                        dispatch(setShopDetail(response.data));
                    } else {
                        dispatch(setShopDetail(null));
                        dispatch(setProducts([]));
                    }
                    dispatch(setLoaderView(false));
                }).catch(() => {
                    dispatch(setShopDetail({}));
                    dispatch(setProducts([]));
                    dispatch(setLoaderView(false));
                })
            }
        }

        bannerSocket.onmessage = function (response) {
            console.log("Banner Socket response received - ", response);
            if (shop) {
                getBanners(shop?.uuid).then((response) => {
                    if (response.status === 200) {
                        dispatch(setBanners(response.data));
                    } else {
                        dispatch(setBanners([]));
                    }
                    dispatch(setLoaderView(false));
                }).catch(() => {
                    dispatch(setBanners([]));
                    dispatch(setLoaderView(false));
                })
            }
        }

        // chatSocket.onclose = function (error) {
        //     console.log('Socket closed unexpectedly!');
        //     console.log(error)
        // };
    }, []);

    useEffect(() => {
        const showPosition = (position) => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            dispatch(setLatitude(latitude));
            dispatch(setLongitude(longitude));
            Geocode.fromLatLng(latitude, longitude).then((response) => {
                //const address = response.results[0].formatted_address; //complete formatted address
                let pincode;
                for (let i = 0; i < response?.results[0]?.address_components?.length; i++) {
                    for (let j = 0; j < response?.results[0]?.address_components[i]?.types?.length; j++) {
                        // eslint-disable-next-line default-case
                        switch (response?.results[0]?.address_components[i]?.types[j]) {
                            case "postal_code":
                                pincode = response?.results[0]?.address_components[i]?.long_name;
                                break;
                        }
                    }
                }
                dispatch(setPIN(pincode));
            });
        }

        const getLocation = () => {
            if (navigator?.geolocation) {
                navigator?.geolocation?.getCurrentPosition(showPosition);
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        }

        if (pin && latitude && longitude) {
            //do nothing
        } else {
            //fetch current location
            getLocation();
        }

    }, [dispatch]);

    return (
        <>
            {/* <FullScreenLoader /> */}
            <Header />
            <Outlet/>
            <Footer />
        </>
    );
}

export default BaseLayout;