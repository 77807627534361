const Features = () => {

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10 col-12">
                        <div className="row text-center align-items-center mb-14">
                            <div class="col-md-6 col-lg-3">
                                <div class="mb-8 mb-xl-0">
                                    <div class="mb-6"><img src="assets/images/icons/clock.svg" alt="" /></div>
                                    <h3 class="h5 mb-3">
                                        10 minute grocery now
                                    </h3>
                                    <p>Get your order delivered to your doorstep at the earliest from FreshCart pickup stores near you.</p>
                                </div>
                            </div>
                            <div class="col-md-6  col-lg-3">
                                <div class="mb-8 mb-xl-0">
                                    <div class="mb-6"><img src="assets/images/icons/gift.svg" alt="" /></div>
                                    <h3 class="h5 mb-3">Best Prices & Offers</h3>
                                    <p>Cheaper prices than your local supermarket, great cashback offers to top it off. Get best pricess &
                                        offers.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="mb-8 mb-xl-0">
                                    <div class="mb-6"><img src="assets/images/icons/package.svg" alt="" /></div>
                                    <h3 class="h5 mb-3">Wide Assortment</h3>
                                    <p>Choose from 5000+ products across food, personal care, household, bakery, veg and non-veg & other
                                        categories.</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="mb-8 mb-xl-0">
                                    <div class="mb-6"><img src="assets/images/icons/refresh-cw.svg" alt="" /></div>
                                    <h3 class="h5 mb-3">Easy Returns</h3>
                                    <p>Not satisfied with a product? Return it at the doorstep & get a refund within hours. No questions asked
                                        <a href="#!">policy</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;