import Currency from 'react-currency-formatter';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, deleteFromWishList, getMyWishList } from "../../api/settingsApi";
import { toast } from "react-toastify";
import { setLoaderView } from "../../redux/actions/settingActions";
import { Link } from 'react-router-dom';

const ProductCard = ({ product, productId, setProducts, setReviews, setProductName }) => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const addProductToCart = () => {
        dispatch(setLoaderView(true));
        addToCart(1, product?.uuid).then(response => {
            if (response.status === 200) {
                toast.success(response.message)
            } else {
                dispatch(setLoaderView(false));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    const deleteItem = () => {
        dispatch(setLoaderView(true));
        deleteFromWishList(productId).then(response => {
            if (response.status === 200) {
                toast.success(response.message)
                getMyWishList().then(response => {
                    setProducts(response?.data);
                    dispatch(setLoaderView(false));
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    toast.error(error.message)
                });
            } else {
                dispatch(setLoaderView(false));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    return (
        <div className="ribbon-box">
            <div className="container cursor-pointer mb-6" onClick={() => {
                setReviews(product?.reviews)
                setProductName(product?.variant_name || product?.name)
            }}>
                <div className="row justify-content-center">
                    <div className="card col-12 p-6">
                        {!product.is_in_stock && <div className="ribbon-two ribbon-two-danger">
                            <span>Out of Stock</span>
                        </div>}
                        {!product.is_visible && <div className="ribbon-two ribbon-two-danger">
                            <span>Unavailable</span>
                        </div>}
                        <div className="row g-4 align-items-center">
                            <div className="col-2 col-sm-3 col-md-2">
                                <img src={product?.image_list[0]?.image_url || `/assets/images/no_product_image.png`} alt="" className="order-summary-image border rounded" />
                            </div>
                            <div className="col-5 col-sm-9 col-md-5 ps-sm-6 ps-6 ps-md-0">
                                <h3 className="mb-1">{product?.variant_name || product?.name}</h3>
                                <div>
                                    <small className="text-warning">
                                        <i className="bi bi-star-fill"></i>
                                        <i className="bi bi-star-fill"></i>
                                        <i className="bi bi-star-fill"></i>
                                        <i className="bi bi-star-fill"></i>
                                        <i className="bi bi-star-half"></i>
                                    </small>
                                    <Link to="/" className="ms-2">(30 reviews)</Link>
                                </div>
                                {product?.variant_discounted_price ? <div className="product-price float-left m-0">
                                    <span className="current-price-small text-primary">
                                        <Currency pattern="!##,##,###." quantity={product?.variant_discounted_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                    </span>
                                    <span>
                                        <span className="save-price-small text-warning">{product?.variant_discounted_percentage}% Off</span>
                                        <span className="old-price-small text-secondary">
                                            <Currency pattern="!##,##,###." quantity={product?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                        </span>
                                    </span>
                                </div>
                                    :
                                    <div className="product-price text-primary float-left m-0">
                                        <span className="current-price-small text-brand">
                                            <Currency pattern="!##,##,###." quantity={product?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                        </span>
                                    </div>
                                }
                            </div>


                            <div className="col-2 col-sm-4 col-md-2 text-right">
                                {(product.is_in_stock && product.is_visible) ?
                                    <button onClick={addProductToCart} className="btn btn-outline-primary btn-sm">Add to Cart</button>
                                    : null}
                            </div>
                            <div className="col-2 col-sm-4 col-md-2 text-right">
                                <b><Currency pattern="!##,##,###." quantity={product?.variant_discounted_price || product?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} /></b>
                            </div>
                            <div className="col-1 col-sm-4 col-md-1 text-right">
                                <i onClick={deleteItem} className="bi bi-trash text-danger fs-4 cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard;