import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Currency from 'react-currency-formatter';
import { toast } from "react-toastify";
import { addToCart, addToWishList, getOrderSummary } from "../../api/settingsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { setOrderSummary } from "../../redux/actions/productActions";

const Product = ({ product }) => {

    const shop = useSelector((state) => state?.shop);

    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const addProductToCart = () => {
        dispatch(setLoaderView(true));
        addToCart(1, product?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    const addProductToWishList = () => {
        dispatch(setLoaderView(true));
        addToWishList(product?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    const fetchOrderSummaryDetails = () => {
        dispatch(setLoaderView(true));
        getOrderSummary(shop?.uuid, product?.uuid, 1).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setOrderSummary({
                    deliveryCharges: response.data?.delivery_charges,
                    discountPercentage: response.data?.discount_percentage,
                    discountAmount: response.data?.discount_amount,
                    netAmount: response.data?.net_amount,
                    gst: response.data?.gst,
                    totalAmount: response.data?.total_amount,
                    unitTotalAmount: response.data?.unit_total_amount,
                    totalGross: response.data?.gross_amount,
                    deliveryTimeperiod: response.data?.delivery_timeperiod,
                    pickupTimeperiod: response.data?.pickup_timeperiod,
                    quantity: 1,
                    productId: product?.uuid
                }))
                navigate(`/${slug}/checkout`);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    return (
        <div className="col">
            <div className="card card-product">
                <div className="card-body">
                    <Link to={`/${slug}/${product?.uuid}`} className="text-center position-relative ">
                        <img src={product?.file_url || "/assets/images/no_product_image.png"} alt="Grocery Ecommerce Template" className={`mb-3 img-fluid default-img zoom-image`} />
                    </Link>
                    {/* <div className="text-small mb-1 text-decoration-none text-muted"><small>{product?.category_name}</small></div> */}
                    <Link to={`/${slug}/${product?.uuid}`}>
                        <h2 className="fs-6 mt-2 text-inherit text-decoration-none product-title">{product?.variant_name || product?.name}</h2>
                    </Link>
                    <div>
                        <small className="text-warning">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-half"></i>
                        </small>
                        <span className="text-muted small">4.5(149)</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        {product?.variant_discounted_percentage > 0 ? <div className="product-price float-left m-0">
                            <span className="current-price-small text-primary">
                                <Currency pattern="!##,##,###." quantity={product?.variant_discounted_price} currency={shop?.currency_abbreviation || 'INR'} />
                            </span>
                            <span>
                                <span className="save-price-small text-warning">{product?.variant_discounted_percentage}% Off</span>
                                <span className="old-price-small text-secondary">
                                    <Currency pattern="!##,##,###." quantity={product?.variant_price} currency={shop?.currency_abbreviation || 'INR'} />
                                </span>
                            </span>
                        </div>
                            :
                            <div className="product-price float-left m-0">
                                <span className="current-price-small primary-color">
                                    <Currency pattern="!##,##,###." quantity={product?.variant_price} currency={shop?.currency_abbreviation || 'INR'} />
                                </span>
                            </div>
                        }
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                        <button onClick={addProductToCart} className="btn btn-secondary P-2">
                            <i className={`${product?.cart_count ? 'bi bi-cart-fill' : 'bi bi-cart'}`}></i>
                        </button>
                        <button onClick={addProductToWishList} className="btn btn-secondary mx-2 P-2">
                            <i className={`${product?.wishlist_count ? 'bi bi-heart-fill' : 'bi bi-heart'}`}></i>
                        </button>
                        <button type="button" onClick={fetchOrderSummaryDetails} disabled={!shop?.is_shop_open} className="btn btn-primary w-100 P-2">
                            <i className='bi bi-bag-check'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product;