import { ActionTypes } from "../constants/actionTypes"

export const productReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PRODUCTS:
            return payload;
        default:
            return state;
    }
}

export const orderReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ORDER_SUMMARY:
            return payload;
        default:
            return state;
    }
}