import { combineReducers } from 'redux';
import { orderReducer, productReducer } from './productReducer';
import { cartReducer, userReducer, wishlistReducer } from './userReducer';
import { bannersReducer, latitudeReducer, loaderReducer, longitudeReducer, pinReducer, shopReducer } from './settingReducer';
import { nearByShopsReducer, searchedProductsReducer, searchedShopsReducer, shopCategoriesReducer } from './marketPlaceReducer';

const reducers = combineReducers({
    products: productReducer,
    order: orderReducer,
    user: userReducer,
    shop: shopReducer,
    loader: loaderReducer,
    pin: pinReducer,
    latitude: latitudeReducer,
    longitude: longitudeReducer,
    banners: bannersReducer,
    searchedShops: searchedShopsReducer,
    searchedProducts: searchedProductsReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    shopCategories: shopCategoriesReducer,
    nearByShops: nearByShopsReducer,
});

export default reducers;