import { auth } from '../../Configuration/FirebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/userActions";
import { authMarketPlaceUser, authShopUser } from '../../api/authenticationApi';
import { checkFirebaseAuthErrorCodeMessage } from '../../helper/Firebase/errorMessages';
import { setLoaderView } from "../../redux/actions/settingActions";

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loader = useSelector((state) => state?.loader);
    const shop = useSelector((state) => state?.shop);

    const [phone, setPhone] = useState(null);
    const [otp, setOtp] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [phoneCountryCode, setPhoneCountryCode] = useState(null);

    const generateReCaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log('Captcha Solved : ', response);
                }
            }, auth);
        }
    }

    const sendOTP = () => {
        if (phone) {
            dispatch(setLoaderView(true));
            generateReCaptcha();
            signInWithPhoneNumber(auth, phoneCountryCode, window.recaptchaVerifier).then((confirmationResult) => {
                toast.success("OTP sent successfully!");
                setConfirmationObj(confirmationResult);
                setOtpSent(true);
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                window.recaptchaVerifier.recaptcha.reset();
                toast.error(checkFirebaseAuthErrorCodeMessage(error.code));
            });
        } else {
            toast.error("Please enter the phone number!")
        }
    }

    const verifyOTP = () => {
        if (otp?.length === 6) {
            dispatch(setLoaderView(true));
            confirmationObj.confirm(otp)
                .then((result) => {
                    if (shop?.uuid) {
                        authShopUser(result?._tokenResponse?.idToken, shop?.uuid, countryCode, phone).then(response => {
                            if (response.status === 200) {
                                toast.success(response.message);
                                dispatch(setUser(response.data));
                                navigate(-1);
                            }
                        }).catch((error) => {
                            if (error.response.data.status === 400) {
                                toast.error(error.response.data.message);
                            }
                        });
                    } else {
                        authMarketPlaceUser(result?._tokenResponse?.idToken, countryCode, phone).then(response => {
                            if (response.status === 200) {
                                toast.success(response.message);
                                dispatch(setUser(response.data));
                                navigate(-1);
                            }
                        }).catch((error) => {
                            if (error.response.data.status === 400) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }
                }).catch((error) => {
                    toast.error(checkFirebaseAuthErrorCodeMessage(error.code));
                    dispatch(setLoaderView(false));
                });
        } else {
            toast.error("Please enter 6 digit OTP!");
        }
    }

    const changePhoneNo = () => {
        setOtpSent(false);
        setPhone(null);
        setOtp(null);
        setPhoneCountryCode(countryCode);
    }

    return (
        <main>
            <section className="my-lg-16 my-8">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-1">
                            <img src="../assets/images/logo.svg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-2">
                            <div className="mt-12">
                                <h1 className="mb-1 h2 fw-bold">Sign in to Shoppers Hunt</h1>
                                <p>Welcome back to Shoppers Hunt!<br />Enter your phone number to get started.</p>
                            </div>
                            <div className="row g-3">
                                <div>
                                    <div className="mb-3">
                                        <PhoneInput country="in" disabled={otpSent} value={phoneCountryCode} onChange={(phoneNumber, countryCodeObj) => {
                                            setPhone(phoneNumber?.substring(countryCodeObj?.dialCode?.length));
                                            setCountryCode(`+${countryCodeObj?.dialCode}`);
                                            setPhoneCountryCode(`+${phoneNumber}`);
                                        }} />
                                        {otpSent && <div className="text-muted text-end cursor-pointer" onClick={changePhoneNo}><small>Change Phone No.?</small></div>}
                                    </div>
                                    {otpSent ?
                                        <>
                                            <div className="my-4 px-4 otp-container">
                                                <OtpInput
                                                    value={otp}
                                                    shouldAutoFocus={true}
                                                    onChange={setOtp}
                                                    isInputNum={true}
                                                    inputStyle="form-control"
                                                    numInputs={6} />
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-primary w-100" type="button" disabled={loader} onClick={verifyOTP}>
                                                    {loader ? <><span className="spinner-grow spinner-grow-sm me-1" role="status"></span>Verifying OTP</> :
                                                        <>Verify OTP</>}
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <div className="text-center">
                                            <button className="btn btn-primary w-100" type="button" disabled={loader} onClick={sendOTP}>
                                                {loader ? <><span className="spinner-grow spinner-grow-sm me-1" role="status"></span>Sending OTP</> :
                                                    <>Send OTP</>}
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* <div className="text-center m-auto">
                                    <hr className="text-muted mb-3" />
                                    <ul className="social-list list-inline mt-3 mb-0">
                                        <li className="list-inline-item">
                                            <span><img src="/assets/images/google.png" alt="" height="72" onClick={signInWithGoogle} className='cursor-pointer' /></span>
                                        </li>
                                    </ul>
                                </div> */}
                                <div id="recaptcha-container" className="text-center mb-3 mb-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;