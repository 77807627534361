import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addNewAddress, getAddresses, upadateDefaultAddress } from "../../api/settingsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import AddAddressPopup from "./AddAddressPopup";
import AddressCard from "./AddressCard";
import DeleteAddressPopup from "./DeleteAddressPopup";
import OrderSummary from "../Common/OrderSummary";
import { setOrderSummary } from "../../redux/actions/productActions";
import { formatOrderStatus } from "../../helper/PaymentDetails/paymentDetailsFormatter";

const Checkout = () => {

    const order = useSelector((state) => state?.order);

    const dispatch = useDispatch();

    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [deliveryType, setDeliveryType] = useState("HOME DELIVERY");
    const [paymentMethod, setPaymentMethod] = useState("ONLINE");
    const [selectedAddressName, setSelectedAddressName] = useState(null);

    useEffect(() => {
        setPaymentMethod("ONLINE");
    }, [deliveryType])

    useEffect(() => {
        window.scrollTo(0, 0);
        const loadAddresses = () => {
            dispatch(setLoaderView(true));
            getAddresses().then(response => {
                setAddresses(response.data);
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message)
            });
        }
        loadAddresses();
    }, []);

    const submitAddNewAddress = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        addNewAddress(formData).then(response => {
            if (response.status === 200) {
                setAddresses(response.data);
                toast.success(response.message);
                document.getElementById('addAddressForm').reset();
                document.getElementById('addAddressModalCloseButton').click();
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    const changeDefaultAddress = (addressid) => {
        upadateDefaultAddress(addressid).then(response => {
            if (response.status === 200) {
                setAddresses(response.data);
                toast.success(response.message);
            }
        }).catch((error) => {
            if (error.response.data.status === 400) {
                toast.error(error.response.data.message);
            }
        });
    }

    useEffect(() => {
        dispatch(setOrderSummary({ ...order, selectedAddress }))
    }, [selectedAddress])

    return (
        <main>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 col-md-8">
                            <div className="py-6 p-md-6 p-lg-6">
                                <div className="d-flex justify-content-between mb-6">
                                    <h2 className="mb-0">Checkout</h2>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">

                                            <div className="accordion-item py-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <a href="#" className="text-inherit collapsed h5 w-100" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <i className="feather-icon icon-clock me-2 text-muted"></i>Delivery Type
                                                    </a>
                                                    <span className="badge bg-primary">{formatOrderStatus(deliveryType)}</span>
                                                </div>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show"
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-xxl-4 col-6 mb-4">
                                                                <div className="card cursor-pointer" onClick={() => setDeliveryType("HOME DELIVERY")}>
                                                                    <div className={`card-body ${deliveryType === 'HOME DELIVERY' ? 'selected-card rounded' : ''} `}>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input cursor-pointer" type="radio" name="deliveryType" id={`delivery_1`} checked={deliveryType === 'HOME DELIVERY'} />
                                                                            <label className="form-check-label text-dark fw-bold cursor-pointer" for={`delivery_1`}>Home Delivery</label>
                                                                        </div>
                                                                        <p className="m-0">Get it in {order?.deliveryTimeperiod}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xxl-4 col-6 mb-4">
                                                                <div className="card cursor-pointer" onClick={() => setDeliveryType("PICK UP")}>
                                                                    <div className={`card-body ${deliveryType === 'PICK UP' ? 'selected-card rounded' : ''} `}>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input cursor-pointer" type="radio" name="deliveryType" id={`delivery_2`} checked={deliveryType === 'PICK UP'} />
                                                                            <label className="form-check-label text-dark fw-bold cursor-pointer" for={`delivery_2`}>Pickup</label>
                                                                        </div>
                                                                        <p className="m-0">Pick it after {order?.pickupTimeperiod}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {deliveryType === "HOME DELIVERY" && <div className="accordion-item py-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <a href="#" className="fs-5 text-inherit collapsed h5 w-100" data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                        <i className="feather-icon icon-map-pin me-2 text-muted"></i>Delivery Address
                                                    </a>
                                                    <span className="badge bg-primary">{selectedAddressName}</span>
                                                </div>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            {addresses?.map((address, index) => {
                                                                return <AddressCard index={index} address={address} setSelectedAddressName={setSelectedAddressName} changeDefaultAddress={changeDefaultAddress} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
                                                            })}
                                                        </div>
                                                        <div className="row  justify-content-center">
                                                            <a href="#" className="btn btn-outline-primary btn-sm w-auto" data-bs-toggle="modal" data-bs-target="#addAddressModal">Add a new address </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            <div className="accordion-item py-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <a href="#" className="text-inherit collapsed h5 w-100" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        <i className="feather-icon icon-clock me-2 text-muted"></i>Payment Method
                                                    </a>
                                                    <span className="badge bg-primary">{formatOrderStatus(paymentMethod)}</span>
                                                </div>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse "
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-xxl-4 col-6 mb-4">
                                                                <div className="card cursor-pointer" onClick={() => setPaymentMethod("ONLINE")}>
                                                                    <div className={`card-body ${paymentMethod === 'ONLINE' ? 'selected-card rounded' : ''} `}>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input cursor-pointer" type="radio" name="paymentMethod" id={`payment_1`} checked={paymentMethod === 'ONLINE'} />
                                                                            <label className="form-check-label text-dark fw-bold cursor-pointer" for={`payment_1`}>Pay Online</label>
                                                                        </div>
                                                                        <p className="m-0">Pay using UPI, Wallets, Credit or Debit Cards, & Netbanking</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {deliveryType !== 'PICK UP' && <div className="col-lg-4 col-xxl-4 col-6 mb-4">
                                                                <div className="card cursor-pointer" onClick={() => setPaymentMethod("COD")}>
                                                                    <div className={`card-body ${paymentMethod === 'COD' ? 'selected-card rounded' : ''} `}>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input cursor-pointer" type="radio" name="paymentMethod" id={`payment_2`} checked={paymentMethod === 'COD'} />
                                                                            <label className="form-check-label text-dark fw-bold cursor-pointer" for={`payment_2`}>COD</label>
                                                                        </div>
                                                                        <p className="m-0">Pay our delivery partner on successfull delivery</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {deliveryType !== 'HOME DELIVERY' && <div className="col-lg-4 col-xxl-4 col-6 mb-4">
                                                                <div className="card cursor-pointer" onClick={() => setPaymentMethod("PAY AND PICK")}>
                                                                    <div className={`card-body ${paymentMethod === 'PAY AND PICK' ? 'selected-card rounded' : ''} `}>
                                                                        <div className="form-check mb-3">
                                                                            <input className="form-check-input cursor-pointer" type="radio" name="paymentMethod" id={`payment_3`} checked={paymentMethod === 'PAY AND PICK'} />
                                                                            <label className="form-check-label text-dark fw-bold cursor-pointer" for={`payment_3`}>Pick & Pay</label>
                                                                        </div>
                                                                        <p className="m-0">Pay at the shop when you pick the order</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 col-md-4">
                            <OrderSummary showPayButton={paymentMethod === 'ONLINE'} showConfirmOrderButton={paymentMethod === 'COD' || paymentMethod === 'PAY AND PICK'} selectedAddress={selectedAddress} />
                        </div>
                    </div>
                </div>
            </section>

            <AddAddressPopup submitAddNewAddress={submitAddNewAddress} />
            <DeleteAddressPopup />
        </main>
    )
}

export default Checkout;