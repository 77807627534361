import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmOrder, getOrderDetails } from "../../api/productsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import OrderStatus from "./OrderStatus";
import Currency from 'react-currency-formatter';
import { parsePaymentDetails } from "../../helper/PaymentDetails/paymentDetailsFormatter";

const PaymentSummary = () => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const [currency] = useState(shop?.currency_abbreviation || 'INR');

    const { orderId, slug } = useParams();
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderSummary, setOrderSummary] = useState(null);
    const [shopOrders, setShopOrders] = useState([]);

    useEffect(() => {
        if (orderId) {
            dispatch(setLoaderView(true));

            confirmOrder(orderId).then(response => {
                if (response.status === 200) {
                    getOrderDetails(orderId).then(response => {
                        //toast.success(response.message);
                        setOrderSummary(response.data);
                        setShopOrders(response.data.shop_order_data);
                        setOrderStatus(response?.data?.payment_details?.order_status);
                        dispatch(setLoaderView(false));
                    }).catch((error) => {
                        dispatch(setLoaderView(false));
                        toast.error(error.message);
                    });
                } else {
                    toast.error(response.message);
                }
            }).catch((error) => {
                if (error.response.data.status === 400) {
                    toast.error(error.response.data.message);
                }
            });


        }
    }, [orderId]);

    return (
        <div>
            <OrderStatus orderStatus={orderStatus} />

            {orderSummary ?
                <>
                    <div className="container my-6">
                        <div className="row justify-content-center">
                            <div className="card col-12 col-md-8 col-lg-8 p-6">
                                <div className="row g-4">
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <h6 className="mb-1">Shipping Address</h6>
                                        <p className="m-0">{orderSummary?.address_details?.full_name}</p>
                                        <p className="m-0">{orderSummary?.address_details?.building_number}</p>
                                        <p className="m-0">{orderSummary?.address_details?.locality}</p>
                                        <p className="m-0">{orderSummary?.address_details?.city}</p>
                                        <p className="m-0">{orderSummary?.address_details?.state}</p>
                                        <p className="m-0">{orderSummary?.address_details?.country}</p>
                                        <p className="m-0">{orderSummary?.address_details?.pincode}</p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <h6 className="mb-1">Payment Details</h6>
                                        <p className="m-0">{parsePaymentDetails(orderSummary?.payment_details?.payment_method_details)?.map((paymentMethodDetail) => {
                                            return (
                                                <div className="list-group-item d-flex align-items-start">
                                                    <div>
                                                        <b>{paymentMethodDetail?.label}: </b>
                                                    </div>
                                                    <div>
                                                        <span className="ms-2">{paymentMethodDetail?.value}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}</p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-1" />
                                    <div className="col-6 col-sm-6 col-md-3">
                                        <h6 className="mb-1">Order Summary</h6>
                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div>Gross Amount</div>
                                            </div>
                                            <span>
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.gross_amount - orderSummary?.discount_amount} currency={currency} />
                                            </span>
                                        </div>
                                        {orderSummary?.offer_amount && <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div>Offer Price</div>
                                            </div>
                                            <span className="text-success">
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.offer_amount} currency={currency} />
                                            </span>
                                        </div>}
                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div>Delivery Charges</div>
                                            </div>
                                            <span className="text-danger">
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.delivery_charges} currency={currency} />
                                            </span>
                                        </div>
                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div className="fw-bold">Net Amount</div>
                                            </div>
                                            <span className="fw-bold">
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.net_amount} currency={currency} />
                                            </span>
                                        </div>
                                        {orderSummary?.gst > 0 ? <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div>GST</div>
                                            </div>
                                            <span className="text-danger">
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.gst} currency={currency} />
                                            </span>
                                        </div> : null}
                                        <div className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="me-auto">
                                                <div className="fw-bold">Total Amount</div>
                                            </div>
                                            <span className="fw-bold">
                                                <Currency pattern="!##,##,###." quantity={orderSummary?.total_amount} currency={currency} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {shopOrders.map((shopOrder) => {
                        return <div className="container my-6">
                            <div className="row justify-content-center">
                                <div className="card bg-light col-12 col-md-8 col-lg-8 px-6 py-4">
                                    <div className="d-flex justify-content-between">
                                        <b className="text-primary m-0">{shopOrder?.shop_details.name}</b>
                                        <div>
                                            <span className="m-0">{shopOrder?.shop_details?.building_number} </span>
                                            <span className="m-0">{shopOrder?.shop_details?.locality} </span>
                                            <span className="m-0">{shopOrder?.shop_details?.city_name} </span>
                                            <span className="m-0">{shopOrder?.shop_details?.pincode}</span>
                                        </div>
                                    </div>
                                    {shopOrder?.order_items?.map((orderItem) => {
                                        return (
                                            <div className="container my-3">
                                                <div className="row justify-content-center">
                                                    <div className="card col-12 p-6">
                                                        <div className="row g-4">
                                                            <div className="col-4 col-sm-4 col-md-2">
                                                                <img src={orderItem?.product_details.image_list[0].image_url || `/assets/images/no_product_image.png`} alt="" className="order-summary-image border rounded" />
                                                            </div>
                                                            <div className="d-sm-block d-md-none col-8 col-sm-8">
                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div>Item Amount</div>
                                                                    </div>
                                                                    <span>
                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.net_amount} currency={currency} />
                                                                    </span>
                                                                </div>
                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div>Quantity</div>
                                                                    </div>
                                                                    <span>{orderItem?.quantity}</span>
                                                                </div>
                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div className="fw-bold">Total Amount</div>
                                                                    </div>
                                                                    <span className="fw-bold">
                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.unit_total_amount} currency={currency} />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-7">
                                                                <div className="mt-md-0">
                                                                    <h3 className="mb-1">{orderItem?.product_details.variant_name || orderItem?.product_details?.name}</h3>
                                                                    <div>
                                                                        <small className="text-warning">
                                                                            <i className="bi bi-star-fill"></i>
                                                                            <i className="bi bi-star-fill"></i>
                                                                            <i className="bi bi-star-fill"></i>
                                                                            <i className="bi bi-star-fill"></i>
                                                                            <i className="bi bi-star-half"></i>
                                                                        </small>
                                                                        <Link to="/" className="ms-2">(30 reviews)</Link>
                                                                    </div>
                                                                    {orderItem?.discount_amount ? <div className="product-price float-left m-0">
                                                                        <span className="current-price-small text-primary">
                                                                            <Currency pattern="!##,##,###." quantity={orderItem?.net_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                        </span>
                                                                        <span>
                                                                            <span className="save-price-small text-warning">{orderItem?.discount_percentage}% Off</span>
                                                                            <span className="old-price-small text-secondary">
                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                        :
                                                                        <div className="product-price text-primary float-left m-0">
                                                                            <span className="current-price-small text-brand">
                                                                                <Currency pattern="!##,##,###." quantity={orderItem?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {orderItem?.product_details?.variant_size && <div className="d-flex">
                                                                        <strong className="me-2">Size: </strong>
                                                                        <p>{orderItem?.product_details?.variant_size}</p>
                                                                    </div>}
                                                                    {orderItem?.product_details?.variant_color && <div className="d-flex">
                                                                        <strong className="me-2">Color:</strong>
                                                                        <div className="color-available" style={{ backgroundColor: orderItem?.product_details?.variant_color }} />
                                                                    </div>}
                                                                </div>
                                                            </div>

                                                            <div className="d-none d-md-block col-md-3">
                                                                <h6 className="mb-1">Item Summary</h6>

                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div>Item Amount</div>
                                                                    </div>
                                                                    <span>
                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.net_amount} currency={currency} />
                                                                    </span>
                                                                </div>
                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div>Quantity</div>
                                                                    </div>
                                                                    <span>{orderItem?.quantity}</span>
                                                                </div>
                                                                <div className="list-group-item d-flex justify-content-between align-items-start">
                                                                    <div className="me-auto">
                                                                        <div className="fw-bold">Total Amount</div>
                                                                    </div>
                                                                    <span className="fw-bold">
                                                                        <Currency pattern="!##,##,###." quantity={orderItem?.unit_total_amount} currency={currency} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    })
                    }


                    <div className="container my-6">
                        <div className="row mb-6">
                            <div className="col-12 text-center">
                                <Link to={slug ? `/${slug}/my-orders` : '/my-orders'} className="btn btn-outline-primary">My Orders</Link>
                                <Link to={slug ? `/${slug}` : '/'} className="btn btn-primary ms-2">Go To Home</Link>
                            </div>
                        </div>
                    </div>
                </>
                : null}
        </div>
    );
}

export default PaymentSummary;