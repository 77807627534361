import { ActionTypes } from "../constants/actionTypes"

export const setShopDetail = (shopDetail) => {
    return {
        type: ActionTypes.SET_SHOP_DETAIL,
        payload: shopDetail
    }
}

export const setPIN = (pin) => {
    return {
        type: ActionTypes.SET_PIN,
        payload: pin
    }
}

export const setLatitude = (latitude) => {
    return {
        type: ActionTypes.SET_LATITUDE,
        payload: latitude
    }
}

export const setLongitude = (longitude) => {
    return {
        type: ActionTypes.SET_LONGITUDE,
        payload: longitude
    }
}

export const setLoaderView = (loaderState) => {
    return {
        type: ActionTypes.SHOW_HIDE_LOADER,
        payload: loaderState
    }
}

export const setBanners = (banners) => {
    return {
        type: ActionTypes.SET_BANNERS,
        payload: banners
    }
}