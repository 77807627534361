import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMyWishList } from "../../api/settingsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { setWishlist } from "../../redux/actions/userActions";
import ProductCard from "./ProductCard";
import CenterErrorMessage from "../Common/CenterErrorMessage";
import ReviewSummary from "../Common/ReviewSummary";

const WishList = () => {

    const dispatch = useDispatch();

    const wishlist = useSelector((state) => state?.wishlist);

    const [reviews, setReviews] = useState(null);
    const [productName, setProductName] = useState(null);
    const [showNoWishListItemMessage, setShowNoWishListItemMessage] = useState(false);

    const setDataToWishlistRedux = (products) => {
        dispatch(setWishlist(products));
    }

    useEffect(() => {
        if (wishlist) {
            if (wishlist?.length === 0) {
                setShowNoWishListItemMessage(true);
            } else if (wishlist?.length > 0) {
                setShowNoWishListItemMessage(false);
                setReviews(wishlist[0]?.product_details?.reviews);
                setProductName(wishlist[0]?.product_details?.variant_name || wishlist[0]?.product_details?.name);
            }
        } else {
            setShowNoWishListItemMessage(true);
        }
    }, [wishlist]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const loadWishListProducts = () => {
            dispatch(setLoaderView(true));
            getMyWishList().then(response => {
                dispatch(setWishlist(response.data));
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message)
            });
        }
        
        loadWishListProducts();
    }, []);

    return (
        <>
            {showNoWishListItemMessage === true && <CenterErrorMessage
                title="Save Your Wish"
                description="An empty wishlist is a missed opportunity. Browse our collection and save the items you adore for future purchase."
                button="Explore our Products"
                path="/" />
            }

            {showNoWishListItemMessage === false && <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-12">
                                <div className="py-6 p-md-6 p-lg-6">
                                    <div className="d-flex justify-content-between mb-6">
                                        <h2 className="mb-0">My WishList</h2>
                                    </div>
                                    <div className="row">
                                        {wishlist?.map((product, index) => {
                                            return <ProductCard productId={product?.uuid} product={product?.product_details} setProductName={setProductName} setReviews={setReviews} setProducts={setDataToWishlistRedux} key={index} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-5">
                                <ReviewSummary productName={productName} reviews={reviews} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            }
        </>
    )
}

export default WishList;