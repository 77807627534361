import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMyCart } from "../../api/settingsApi";
import { setLoaderView } from "../../redux/actions/settingActions";
import { setOrderSummary } from "../../redux/actions/productActions";
import ProductCard from "./ProductCard";
import OrderSummary from "../Common/OrderSummary";
import CenterErrorMessage from "../Common/CenterErrorMessage";
import { setCart } from "../../redux/actions/userActions";

const Cart = () => {

    const dispatch = useDispatch();

    const cart = useSelector((state) => state?.cart);

    const [showNoCartItemMessage, setShowNoCartItemMessage] = useState(false);

    useEffect(() => {
        if (cart) {
            if (cart && cart?.length === 0) {
                setShowNoCartItemMessage(true);
            } else {
                setShowNoCartItemMessage(false);
            }
        } else {
            setShowNoCartItemMessage(true);
        }
    }, [cart]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const loadCartProducts = () => {
            dispatch(setLoaderView(true));
            getMyCart().then(response => {
                dispatch(setCart(response?.data?.rows));
                dispatch(setOrderSummary({
                    deliveryCharges: response.data?.delivery_charges,
                    discountAmount: response.data?.discount_amount,
                    netAmount: response.data?.net_amount,
                    gst: response.data?.gst,
                    totalAmount: response.data?.total_amount,
                    totalGross: response.data?.total_gross,
                    deliveryTimeperiod: response.data?.delivery_timeperiod,
                    pickupTimeperiod: response.data?.pickup_timeperiod
                }));
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                toast.error(error.message)
            });
        }
        loadCartProducts();
    }, []);

    const setDataToCartRedux = (products) => {
        dispatch(setCart(products));
    }

    return (
        <>
            {showNoCartItemMessage === true && <CenterErrorMessage
                title="Empty Cart? No Way!"
                description="An empty cart is like an empty jar, add some shopping and make it a star"
                button="Explore our Products"
                path="/" />
            }

            {showNoCartItemMessage === false && <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-12">
                                <div className="py-6 p-md-6 p-lg-6">
                                    <div className="d-flex justify-content-between mb-6">
                                        <h2 className="mb-0">My Cart</h2>
                                    </div>
                                    <div className="row">
                                        {cart?.map((product, index) => {
                                            return <ProductCard product={product} setProducts={setDataToCartRedux} key={index} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-5">
                                <OrderSummary showCheckoutButton={cart?.length > 0 ? true : false} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            }
        </>
    )
}

export default Cart;