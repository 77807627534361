const GetMobileApp = () => {

    return (
        <section className="mb-lg-14 mb-8">
            <div class="container">
                <div class="row align-items-center">
                    <div class="offset-lg-2 col-lg-4 col-md-6">
                        <div class="text-center">

                            <img src="../assets/images/png/iphone-2.png" alt="" class=" img-fluid" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="mb-6">
                            <div class="mb-7">

                                <h2>Get the FreshCart app</h2>
                                <p class="mb-0">We will send you a link, open it on your phone to download the app.</p>
                            </div>
                            <div class="mb-5">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Email
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Phone
                                    </label>
                                </div>
                                <form class="row g-3 mt-1">
                                    <div class="col-lg-6 col-7">
                                        <input type="text" class="form-control" placeholder="Phone" />
                                    </div>
                                    <div class="col-auto">
                                        <button type="submit" class="btn btn-primary mb-3">Share app link</button>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <small>Download app from</small>
                                <ul class="list-inline mb-0 mt-3">
                                    <li class="list-inline-item">

                                        <a href="#!"> <img src="../assets/images/appbutton/appstore-btn.svg" alt=""
                                            style={{ width: "140px" }} /></a></li>
                                    <li class="list-inline-item">

                                        <a href="#!"> <img src="../assets/images/appbutton/googleplay-btn.svg" alt=""
                                            style={{ width: "140px" }} /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetMobileApp;