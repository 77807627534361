import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import AuthLayout from './layouts/AuthLayout/AuthLayout';
import { ToastContainer } from 'react-toastify';
import ProductDetails from './Components/Products/ProductDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderView } from './redux/actions/settingActions';
import PageNotFound from './Components/Common/PageNotFound';
import { adjustColor, hexToRgb } from './helper/Color/colorFormatter';
import MarketPlace from './Components/Home/MarketPlace/MarketPlace';
import ShopProducts from './Components/Home/Shop/ShopProducts';
import Login from './Components/Authentication/Login';
import MyOrders from './Components/Orders/MyOrders';
import Cart from './Components/Cart/Cart';
import WishList from './Components/WishList/WishList';
import PaymentSummary from './Components/Orders/PaymentSummary';
import Checkout from './Components/Checkout/Checkout';
import { useEffect } from 'react';

function App() {

    const dispatch = useDispatch();
    dispatch(setLoaderView(false));

    const shop = useSelector((state) => state?.shop);

    useEffect(() => {
        document.documentElement.style.setProperty('--custom-primary', shop?.primary_color ? shop?.primary_color : process.env.REACT_APP_PRIMARY_COLOR);
        document.documentElement.style.setProperty('--custom-primary-dark', shop?.primary_color ? shop?.primary_color : adjustColor(process.env.REACT_APP_PRIMARY_COLOR, -20));
        document.documentElement.style.setProperty('--custom-primary-border', shop?.primary_color ? shop?.primary_color : adjustColor(process.env.REACT_APP_PRIMARY_COLOR, -40));
        document.documentElement.style.setProperty('--custom-primary-focus', shop?.primary_color ? shop?.primary_color : process.env.REACT_APP_PRIMARY_COLOR + '26');
        document.documentElement.style.setProperty('--custom-primary-rgb', shop?.primary_color ? shop?.primary_color : hexToRgb(process.env.REACT_APP_PRIMARY_COLOR));

        document.documentElement.style.setProperty('--custom-secondary', shop?.secondary_color ? shop?.secondary_color : process.env.REACT_APP_SECONDARY_COLOR);
        document.documentElement.style.setProperty('--custom-secondary-dark', shop?.secondary_color ? shop?.secondary_color : adjustColor(process.env.REACT_APP_SECONDARY_COLOR, -20));
        document.documentElement.style.setProperty('--custom-secondary-border', shop?.secondary_color ? shop?.secondary_color : adjustColor(process.env.REACT_APP_SECONDARY_COLOR, -40));
        document.documentElement.style.setProperty('--custom-secondary-focus', shop?.secondary_color ? shop?.secondary_color : process.env.REACT_APP_SECONDARY_COLOR + '26');
        document.documentElement.style.setProperty('--custom-secondary-rgb', shop?.secondary_color ? shop?.secondary_color : hexToRgb(process.env.REACT_APP_SECONDARY_COLOR));

        document.title = shop?.name ? (shop?.name + ' - Shoppers Hunt') : 'Shoppers Hunt';
        document.getElementById('fav_icon').setAttribute('href', shop?.fav_icon);

    }, [shop]);

    return (
        <BrowserRouter>
            <ToastContainer />
            <Routes>
                <Route element={<BaseLayout />} >
                    <Route path="/" element={<MarketPlace />} />
                    <Route path="/:slug" element={<ShopProducts />} />
                    <Route path="/:slug/:productId" element={<ProductDetails />} />

                    <Route path="/:slug/my-orders" element={<MyOrders />} />
                    <Route path="/my-orders" element={<MyOrders />} />

                    <Route path="/:slug/my-cart" element={<Cart />} />
                    <Route path="/my-cart" element={<Cart />} />

                    <Route path="/:slug/my-wish-list" element={<WishList />} />
                    <Route path="/my-wish-list" element={<WishList />} />

                    <Route path="/:slug/checkout" element={<Checkout />} />
                    <Route path="/checkout" element={<Checkout />} />

                    <Route path="/my-orders/:orderId" element={<PaymentSummary />} />

                    <Route path="/:slug/my-profile" element={<h1>my-profile</h1>} />
                    <Route path="/my-profile" element={<h1>my-profile</h1>} />

                    <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route element={<AuthLayout />} >
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/:slug/login" element={<Login />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;