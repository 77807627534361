import Label from './Label';

const Input = ({ label, type, placeholder, id, max, readOnly, disabled, name, className, labelClassName, required, value, onKeyDown, defaultValue, onChange }) => {
    return (
        <>
            <Label labelClassName={labelClassName} max={max} label={label} required={required} />
            <input className={className} id={id} readOnly={readOnly} disabled={disabled} name={name} value={value} onChange={onChange} onKeyDown={onKeyDown} defaultValue={defaultValue} type={type} required={required} placeholder={placeholder} />
        </>
    )
}

export default Input;