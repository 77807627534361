export const parsePaymentDetails = (data) => {
    if (data) {
        let paymentMethod = Object.keys(data)[0];
        let remainingObject = data[paymentMethod];
        let keys = Object.keys(data[paymentMethod]);
        let arr = [];

        arr.push({
            label: 'Payment Method', value: formatString(paymentMethod)
        });

        for (let i = 0; i < keys.length; i++) {
            if (remainingObject[keys[i]]) {
                arr.push({
                    label: formatString(keys[i]).replace('Netbanking', ''), value: formatString(remainingObject[keys[i]])
                })
            }
        }
        return arr;
    }
}

export const parsePaymentMethod = (data) => {
    if (data) {
        let paymentMethod = Object.keys(data)[0];
        let arr = [];

        arr.push({
            label: 'Payment Method', value: formatString(paymentMethod)
        });
        return arr;
    }
}

const formatString = (data) => {
    if (data) {
        const arr = data.split("_");
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
    } else {
        return "";
    }
}

export const formatOrderStatus = (data) => {
    if (data) {
        return data[0].toUpperCase() + data.substring(1).toLowerCase()
    }
}