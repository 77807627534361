import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { logoutUser } from '../../../redux/actions/userActions';
import PinPickerPopup from './PinPickerPopup';
import { setSearchedProducts, setSearchedShops } from '../../../redux/actions/marketPlaceActions';
// import { searchShopsOrProducts } from '../../../api/marketPlaceApi';

const TopBar = () => {

    const { slug } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shop = useSelector((state) => state?.shop);
    const user = useSelector((state) => state?.user);
    const pin = useSelector((state) => state?.pin);

    const searchedShops = useSelector((state) => state?.searchedShops);
    const searchedProducts = useSelector((state) => state?.searchedProducts);

    const searchShopOrProduct = (searchString) => {
        if (searchString.length >= 3) {

            let res = {
                "products_data": [
                    {
                        "variant_name": "iPhone 14 Pro - Silver",
                        "category_name": "Electrical",
                        "sub_category_name": "Mobile",
                        "reviews": 0,
                        "image_list": {
                            "id": 219,
                            "image_url": "/opt/shoppershunt-backend/media/shop_15/products/product_184/images/61lKQWyMdDL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_15/products/product_184/images/61lKQWyMdDL._SX679_.jpg",
                            "created_on": "2023-03-04T22:25:00.612304Z",
                            "modified_on": "2023-03-04T22:25:00.612331Z",
                            "is_active": true,
                            "product_id": 184
                        }
                    },
                    {
                        "variant_name": "iPhone 13 - Red",
                        "category_name": "Electrical Appliances",
                        "sub_category_name": "Mobile",
                        "reviews": 0,
                        "image_list": {
                            "id": 64,
                            "image_url": "/opt/shoppershunt-backend/media/shop_6/products/product_58/images/61F7Xcyux0L._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_6/products/product_58/images/61F7Xcyux0L._SX679_.jpg",
                            "created_on": "2023-01-04T21:05:20.226330Z",
                            "modified_on": "2023-01-04T21:05:20.226351Z",
                            "is_active": true,
                            "product_id": 58
                        }
                    },
                    {
                        "variant_name": "iPhone 14 Pro - Golden",
                        "category_name": "Electronic Appliances",
                        "sub_category_name": "Mobile Phones",
                        "reviews": 0,
                        "image_list": {
                            "file_name": null,
                            "is_active": false,
                            "product_id": null
                        }
                    },
                    {
                        "variant_name": "iPhone 14 pro white",
                        "category_name": "Mobile",
                        "sub_category_name": "Apple",
                        "reviews": 0,
                        "image_list": {
                            "id": 90,
                            "image_url": "/opt/shoppershunt-backend/media/shop_11/products/product_84/images/61lKQWyMdDL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_11/products/product_84/images/61lKQWyMdDL._SX679_.jpg",
                            "created_on": "2023-01-08T15:18:57.276880Z",
                            "modified_on": "2023-01-08T15:18:57.276907Z",
                            "is_active": true,
                            "product_id": 84
                        }
                    },
                    {
                        "variant_name": "iPhone 14 pro black",
                        "category_name": "Mobile",
                        "sub_category_name": "Apple",
                        "reviews": 0,
                        "image_list": {
                            "id": 89,
                            "image_url": "/opt/shoppershunt-backend/media/shop_11/products/product_83/images/61XO4bORHUL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_11/products/product_83/images/61XO4bORHUL._SX679_.jpg",
                            "created_on": "2023-01-08T15:18:57.256066Z",
                            "modified_on": "2023-01-08T15:18:57.256103Z",
                            "is_active": true,
                            "product_id": 83
                        }
                    },
                    {
                        "variant_name": "iPhone 14 pro golden",
                        "category_name": "Mobile",
                        "sub_category_name": "Apple",
                        "reviews": 0,
                        "image_list": {
                            "file_name": null,
                            "is_active": false,
                            "product_id": null
                        }
                    },
                    {
                        "variant_name": "iPhone 13 - Pink",
                        "category_name": "Electrical Appliances",
                        "sub_category_name": "Mobile",
                        "reviews": 0,
                        "image_list": {
                            "id": 67,
                            "image_url": "/opt/shoppershunt-backend/media/shop_6/products/product_59/images/61nPiOOv9BL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_6/products/product_59/images/61nPiOOv9BL._SX679_.jpg",
                            "created_on": "2023-01-04T21:05:20.249462Z",
                            "modified_on": "2023-01-04T21:05:20.249485Z",
                            "is_active": true,
                            "product_id": 59
                        }
                    },
                    {
                        "variant_name": "iPhone 14 Pro - Gold",
                        "category_name": "Electrical Appliances",
                        "sub_category_name": "Mobile",
                        "reviews": 0,
                        "image_list": {
                            "id": 59,
                            "image_url": "/opt/shoppershunt-backend/media/shop_6/products/product_54/images/71ZDY57yTQL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_6/products/product_54/images/71ZDY57yTQL._SX679_.jpg",
                            "created_on": "2023-01-04T20:00:18.257569Z",
                            "modified_on": "2023-01-04T20:00:18.257590Z",
                            "is_active": true,
                            "product_id": 54
                        }
                    },
                    {
                        "variant_name": "iPhone 14 Pro - Silver",
                        "category_name": "Electrical Appliances",
                        "sub_category_name": "Mobile",
                        "reviews": 0,
                        "image_list": {
                            "id": 60,
                            "image_url": "/opt/shoppershunt-backend/media/shop_6/products/product_55/images/61lKQWyMdDL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_6/products/product_55/images/61lKQWyMdDL._SX679_.jpg",
                            "created_on": "2023-01-04T20:00:18.285250Z",
                            "modified_on": "2023-01-04T20:00:18.285273Z",
                            "is_active": true,
                            "product_id": 55
                        }
                    },
                    {
                        "variant_name": "iPhone 14 Pro - Silver",
                        "category_name": "Electronic Appliances",
                        "sub_category_name": "Mobile Phones",
                        "reviews": 0,
                        "image_list": {
                            "id": 86,
                            "image_url": "/opt/shoppershunt-backend/media/shop_4/products/product_79/images/61lKQWyMdDL._SX679_.jpg",
                            "file_name": "/media/opt/shoppershunt-backend/media/shop_4/products/product_79/images/61lKQWyMdDL._SX679_.jpg",
                            "created_on": "2023-01-07T21:59:00.127612Z",
                            "modified_on": "2023-01-07T21:59:00.127641Z",
                            "is_active": true,
                            "product_id": 79
                        }
                    }
                ],
                "shops_data": [
                    {
                        "name": "Iphone Store",
                        "category_id": 1,
                        "category_name": "Electronics",
                        "uuid": "b1371db9-e106-4cd4-ba6e-1521fcadf87c",
                        "slug": "general-store",
                        "current_shop_status": false,
                        "logo": "/shop_logo/mcdonalds-logo.webp",
                        "fav_icon": "/shop_favicon/mcdonalds-logo.webp",
                        "is_open": true,
                        "building_number": "C-85",
                        "locality": "GTB Nagar, Kareli",
                        "city_id": 147429,
                        "city_name": "Allahabad",
                        "state_id": 4022,
                        "state_name": "Uttar Pradesh",
                        "country_id": 101,
                        "country_name": "India",
                        "pincode": 2,
                        "latitude": "18.485132",
                        "longitude": "73.8110947"
                    }
                ]
            }

            dispatch(setSearchedShops(res.shops_data))
            dispatch(setSearchedProducts(res.products_data))

            // searchShopsOrProducts(searchString).then(response => {
            //     if (response.status === 200) {
            //         // toast.success(response.data.products_data.length)
            //         // toast.success(response.data.shops_data.length)
            //     }
            // }).catch((error) => {
            //     toast.error(error.message)
            // });
        }
    }

    return (
        <div className="navbar navbar-light py-lg-3 pt-1 px-0 pb-0">
            <div className="container">
                <div className="row w-100 align-items-center g-lg-2 g-0">
                    <div className="col-xxl-2 col-lg-3">

                        <Link to={shop?.slug ? `/${shop?.slug}` : '/'} className='navbar-brand d-none d-lg-block'>
                            <img className='top-logo' src={shop?.logo_url || "/assets/images/logo.svg"} alt="logo" />
                        </Link>

                        <div className="d-flex justify-content-between w-100 d-lg-none">

                            <Link to={shop?.slug ? `/${shop?.slug}` : '/'} className='navbar-brand'>
                                <img className='top-logo' src={shop?.logo_url || "/assets/images/logo.svg"} alt="logo" />
                            </Link>

                            <div className="d-flex align-items-center lh-1">
                                <div className="list-inline top-bar-icons">
                                    <div className="list-inline-item">
                                        <Link to={(slug && shop?.slug === slug) ? `/${slug}/my-wish-list` : '/my-wish-list'} className="text-muted cursor-pointer position-relative">
                                            <i className='bi bi-heart fs-3'></i>
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">1<span className="visually-hidden">unread messages</span></span>
                                        </Link>
                                    </div>
                                    <div className="list-inline-item mx-4">
                                        <Link to={(slug && shop?.slug === slug) ? `/${slug}/my-cart` : '/my-cart'} className="text-muted cursor-pointer position-relative">
                                            <i className='bi bi-cart fs-3'></i>
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">1<span className="visually-hidden">unread messages</span></span>
                                        </Link>
                                    </div>
                                </div>

                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#navbar-default" aria-controls="navbar-default" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="icon-bar top-bar mt-0"></span>
                                    <span className="icon-bar middle-bar"></span>
                                    <span className="icon-bar bottom-bar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-lg-5 d-none d-lg-block">
                        <form action="#">
                            <div className="input-group ">
                                <input id="dropdownMenuButton1" data-bs-toggle="dropdown" className="form-control rounded-3" onChange={(event) => searchShopOrProduct(event.target.value)} type="text" placeholder='Enter product or shop name' />
                                <ul className="dropdown-menu w-100 search-drop-down" aria-labelledby="dropdownMenuButton1">
                                    {searchedShops?.map((shop) => {
                                        return <li>
                                            <Link to={shop.slug} class="d-flex border-bottom pb-2 pt-2">
                                                <img src={`${process.env.REACT_APP_API_URL}/media${shop.logo}`} alt="" class="rounded avatar-lg" />
                                                <div class="ms-3">
                                                    <h6 class="mb-1">{shop.name}</h6>
                                                    <p class="small"> <span class="text-muted">{shop.category_name}</span>
                                                        {/* <span class="text-primary ms-3 fw-bold">Verified Purchase</span> */}
                                                    </p>
                                                </div>
                                            </Link>
                                        </li>
                                    })}
                                    {searchedProducts?.map((product) => {
                                        return <li>
                                            <div class="d-flex border-bottom pb-2 pt-2">
                                                <img src={`${process.env.REACT_APP_API_URL}${product?.image_list?.image_url?.replace("/opt/shoppershunt-backend", "")}`} alt="" class="rounded avatar-lg" />
                                                <div class="ms-3">
                                                    <h6 class="mb-1">{product.variant_name}</h6>
                                                    <p class="small"> <span class="text-muted">{product.category_name}</span>
                                                        {/* <span class="text-primary ms-3 fw-bold">Verified Purchase</span> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    })}
                                </ul>

                                <span className="input-group-append">
                                    <button className="btn bg-white border border-start-0 ms-n10" type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </button>
                                </span>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-2 col-xxl-3 d-none d-lg-block">
                        <button type="button" className="btn  btn-outline-gray-400 text-muted" data-bs-toggle="modal" data-bs-target="#locationModal">
                            <i className={`bi bi-geo-alt-fill ${pin ? 'text-success' : 'text-danger'}`}></i>
                            <span className='ms-2'>{pin}</span>
                        </button>
                    </div>
                    <div className="col-md-2 col-xxl-1 text-end d-none d-lg-block">
                        <div className="list-inline top-bar-icons">
                            <div className="list-inline-item">
                                <Link to={(slug && shop?.slug === slug) ? `/${slug}/my-wish-list` : '/my-wish-list'} className="text-muted cursor-pointer position-relative">
                                    <i className='bi bi-heart fs-3'></i>
                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">1<span className="visually-hidden">unread messages</span></span>
                                </Link>
                            </div>
                            <div className="list-inline-item mx-4">
                                <Link to={(slug && shop?.slug === slug) ? `/${slug}/my-cart` : '/my-cart'} className="text-muted cursor-pointer position-relative">
                                    <i className='bi bi-cart fs-3'></i>
                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">1<span className="visually-hidden">unread messages</span></span>
                                </Link>
                            </div>

                            <div className="dropdown list-inline-item">
                                <div className="text-muted cursor-pointer position-relative ms-1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='bi bi-person-circle fs-3'></i>
                                </div>
                                <ul className="dropdown-menu top-bar-dropdown-menu">
                                    {user?.token ? <>
                                        <li>
                                            <Link className='dropdown-item' to={(slug ? `/${slug}/my-profile` : '/my-profile')}>
                                                <i className="bi bi-pencil-square me-3 "></i>My Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className='dropdown-item' to={(slug && shop?.slug === slug) ? `/${slug}/my-orders` : '/my-orders'}>
                                                <i className="bi bi-pencil-square me-3 "></i>My Orders
                                            </Link>
                                        </li>
                                        <li>
                                            <div onClick={() => {
                                                dispatch(logoutUser());
                                                navigate(slug ? `/${slug}/login` : '/login');
                                            }} className="dropdown-item cursor-pointer">
                                                <i className="bi bi-pencil-square me-3"></i>Logout
                                            </div>
                                        </li>
                                    </>
                                        :
                                        <li>
                                            <Link className='dropdown-item' to={(slug ? `/${slug}/login` : '/login')}>
                                                <i className="bi bi-pencil-square me-3"></i>Login
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PinPickerPopup />
        </div >
    );
}

export default TopBar;