import { useState } from "react";

const Counter = ({ value = null, name, setQuantity }) => {

    const [count, setCount] = useState(value || 1);

    const changeCount = (updatedCount) => {
        setCount(updatedCount);
        setQuantity(updatedCount);
    }

    return (
        <div className="input-group input-spinner mt-3">
            <input type="button" value="-" onClick={() => {
                if (count > 1) {
                    changeCount(count - 1)
                }
            }} className="button-minus btn btn-sm" />

            <input type="number" step="1" onChange={(event) => changeCount(event.target.value)} max="100" value={count} name={name} className="quantity-field form-control-sm form-input" />

            <input type="button" value="+" onClick={() => {
                if (count <= 100) {
                    changeCount(count + 1)
                }
            }} className="button-plus btn btn-sm" />
        </div>
    )
}

export default Counter;