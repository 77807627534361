import { ActionTypes } from "../constants/actionTypes"

export const shopCategoriesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_SHOP_CATEGORIES:
            return payload;
        default:
            return state;
    }
}

export const nearByShopsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_NEAR_BY_SHOPS:
            return payload;
        default:
            return state;
    }
}

export const searchedShopsReducer = (state = [], { type, payload = [] }) => {
    switch (type) {
        case ActionTypes.SET_SEARCHED_SHOPS:
            return payload;
        default:
            return state;
    }
}

export const searchedProductsReducer = (state = [], { type, payload = [] }) => {
    switch (type) {
        case ActionTypes.SET_SEARCHED_PRODUCTS:
            return payload;
        default:
            return state;
    }
}