import { useState } from "react";
import { useEffect } from "react";

const OrderStatus = ({ orderStatus }) => {

    const [color, setColor] = useState(null);
    const [message, setMessage] = useState(null);
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        //if (orderStatus === 'PAID') {
        if (orderStatus === 'TXN_SUCCESS') {
            setColor("success");
            setMessage("Payment Completed Successfully!");
            setIcon("success");
        } else if (orderStatus === 'ACTIVE') {
            setColor("danger");
            setMessage("Payment Failed!");
            setIcon("failure");
        } else if (orderStatus === 'EXPIRED') {
            setColor("warning");
            setMessage("Payment is pending!");
            setIcon("pending");
        }else{
            setColor("dark");
            setMessage("Payment status not found!");
            setIcon("failure");
        }
    }, [orderStatus])

    return (
        <div className={`p-10 bg-${color} text-white d-flex justify-content-center align-items-center`}>
            <img src={`/assets/images/${icon}.png`} className="order-status-icon" alt="" />
            <h1 className="text-white ms-10">{message}</h1>
        </div>
    )
}

export default OrderStatus;