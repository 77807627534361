import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLoaderView } from "../../redux/actions/settingActions";
import { fetchProduct } from "../../api/productsApi";
import { toast } from "react-toastify";
import Currency from 'react-currency-formatter';
import ImageSlider from "./ImageSlider";
import Counter from "../Common/Counter";
import { addToCart, addToWishList, getOrderSummary } from "../../api/settingsApi";
import { setOrderSummary } from "../../redux/actions/productActions";
import parse from "html-react-parser";

const ProductDetails = () => {

    const shop = useSelector((state) => state?.shop);
    const { productId, slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (productId) {
            // dispatch(setLoaderView(true));
            fetchProduct(productId, shop?.uuid).then(response => {
                setProduct(response.data);
                dispatch(setLoaderView(false));
            }).catch((error) => {
                dispatch(setLoaderView(false));
                //toast.error(error.message)
            });
        }
    }, [shop?.uuid, productId]);

    const addProductToCart = () => {
        dispatch(setLoaderView(true));
        addToCart(quantity, productId).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    const addProductToWishList = () => {
        dispatch(setLoaderView(true));
        addToWishList(product?.uuid).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                toast.success(response.message)
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    const fetchOrderSummaryDetails = () => {
        dispatch(setLoaderView(true));
        getOrderSummary(shop?.uuid, productId, quantity).then(response => {
            dispatch(setLoaderView(false));
            if (response.status === 200) {
                dispatch(setOrderSummary({
                    deliveryCharges: response.data?.delivery_charges,
                    discountPercentage: response.data?.discount_percentage,
                    discountAmount: response.data?.discount_amount,
                    netAmount: response.data?.net_amount,
                    gst: response.data?.gst,
                    totalAmount: response.data?.total_amount,
                    unitTotalAmount: response.data?.unit_total_amount,
                    totalGross: response.data?.gross_amount,
                    deliveryTimeperiod: response.data?.delivery_timeperiod,
                    pickupTimeperiod: response.data?.pickup_timeperiod,
                    quantity: quantity,
                    productId: productId
                }))
                navigate(`/${slug}/checkout`);
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }


    return (
        <main>
            <section className="my-8">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">

                                <div className="col-md-6">
                                    <ImageSlider productImages={product?.image_list} />
                                </div>

                                <div className="col-md-6">
                                    <div className="ps-lg-10 mt-6 mt-md-0 mb-6">
                                        <b>{product?.brand}</b>
                                        <h1 className="mb-1">{product?.variant_name || product?.name}</h1>
                                        <div>
                                            <small className="text-warning">
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-half"></i>
                                            </small>
                                            <Link to="/" className="ms-2">(30 reviews)</Link>
                                        </div>

                                        {product?.variant_discounted_percentage ? <div className="product-price float-left mb-0">
                                            <span className="current-price text-primary">
                                                <Currency pattern="!##,##,###." quantity={product?.variant_discounted_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                            </span>
                                            <span>
                                                <span className="save-price text-warning">{product?.variant_discounted_percentage}% Off</span>
                                                <span className="old-price text-secondary">
                                                    <Currency pattern="!##,##,###." quantity={product?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                </span>
                                            </span>
                                        </div>
                                            :
                                            <div className="product-price text-primary float-left">
                                                <span className="current-price text-brand">
                                                    <Currency pattern="!##,##,###." quantity={product?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                </span>
                                            </div>
                                        }


                                        {product?.variant_size && <div className="d-flex">
                                            <strong className="me-2">Size: </strong>
                                            <p>{product?.variant_size}</p>
                                        </div>}
                                        {product?.variant_color && <div className="d-flex">
                                            <strong className="me-2">Color:</strong>
                                            <div className="color-available" style={{ backgroundColor: product.variant_color }} />
                                        </div>}

                                        <Counter setQuantity={setQuantity} />

                                        <div className="mt-3 row justify-content-start g-2 align-items-center">
                                            <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
                                                <button type="button" disabled={!shop?.is_shop_open} onClick={fetchOrderSummaryDetails} className="btn btn-primary"><i className="feather-icon icon-shopping-bag me-2"></i>Buy</button>
                                            </div>
                                            <div className="col-md-4 col-4">
                                                <button onClick={addProductToCart} className="btn btn-secondary me-2">
                                                    <i className="bi bi-cart"></i>
                                                </button>
                                                <button onClick={addProductToWishList} className="btn btn-secondary me-2">
                                                    <i className="feather-icon icon-heart"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <p className="mt-3">{parse(product?.variant_description || "")}</p>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {product?.variant_list?.length > 0 && <div className=" col-md-3 height-fit-content mt-3 mb-6">
                            <div className="card">
                                <div className="d-flex justify-content-between mb-2 align-items-center">
                                    <h4 className="m-3 mb-1">Other Variants</h4>
                                </div>

                                <ul className="list-group list-group-flush">
                                    {product?.variant_list?.map((productVariant, index) => {
                                        return <Link to={`/${slug}/${productVariant?.uuid}`} key={index} className="list-group-item py-5">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <img className="variant-img zoom-image" src={productVariant?.image_list[0]?.image_url} alt="" />
                                                    <div className="ms-4">
                                                        <h5 className="mb-0 h6 h6">{productVariant.variant_name}</h5>

                                                        {productVariant?.variant_discounted_percentage ? <div className="product-price float-left m-0">
                                                            <span className="current-price-small text-primary">
                                                                <Currency pattern="!##,##,###." quantity={productVariant?.variant_discounted_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                            </span>
                                                            <span>
                                                                <span className="save-price-small text-warning">{productVariant?.variant_discounted_percentage}% Off</span>
                                                                <span className="old-price-small text-secondary">
                                                                    <Currency pattern="!##,##,###." quantity={productVariant?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                            :
                                                            <div className="product-price-small primary-color float-left">
                                                                {/* <span className="current-price-small text-brand">₹{productVariant?.variant_price}</span> */}
                                                                <span className="current-price-small text-brand">
                                                                    <Currency pattern="!##,##,###." quantity={productVariant?.variant_price || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    })}
                                </ul>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ProductDetails;