import Input from "./Input";
import { useEffect, useState } from "react";
import MapPicker from 'react-google-map-picker'
import Geocode from "react-geocode";
import SearchDropDown from "./SearchDropDown";
import { fetchCities, fetchCountries, fetchPinCodes, fetchStates } from "../../api/settingsApi";
import { formatValueLabel } from "../../helper/SelectDropDown/valueLabelFormatter";
import { useDispatch, useSelector } from "react-redux";
import Label from "./Label";
import { setLatitude, setLongitude } from "../../redux/actions/settingActions";

//const DefaultLocation = { lat: 25.4283568, lng: 81.8206944 };
const DefaultZoom = 18;

const AddressPicker = ({ latitude, longitude }) => {

    const dispatch = useDispatch();

    const latitudeRedux = useSelector((state) => state?.latitude);
    const longitudeRedux = useSelector((state) => state?.longitude);
    const pin = useSelector((state) => state?.pin);

    //map states
    const [defaultLocation, setDefaultLocation] = useState({ lat: Number(latitude), lng: Number(longitude) });
    const [zoom] = useState(DefaultZoom);

    useEffect(() => {
        if (latitude && longitude) {
            setDefaultLocation({ lat: Number(latitude), lng: Number(longitude) })
        } else {
            setDefaultLocation({ lat: Number(latitudeRedux), lng: Number(longitudeRedux) })
        }
    }, [latitude, longitude, latitudeRedux, longitudeRedux]);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

    //address states
    const [shopNo, setShopNo] = useState(null);
    const [locality, setLocality] = useState(null);

    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [country, setCountry] = useState(null);
    const [pinCode, setPinCode] = useState(null);

    //const [completeAddress, setCompleteAddress] = useState(null);
    const [updateLocation, setUpdateLocation] = useState(false);

    const [cityId, setCityId] = useState();
    const [stateId, setStateId] = useState();
    const [countryId, setCountryId] = useState();
    const [pinCodeId, setPinCodeId] = useState();

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [pinCodes, setPinCodes] = useState([]);
    const [pinCodesData, setPinCodesData] = useState([]);

    const handleChangeLocation = (lat, lng) => {

        dispatch(setLatitude(lat))
        dispatch(setLongitude(lng))

        setUpdateLocation(false);

        Geocode.fromLatLng(lat, lng).then((response) => {
            //const address = response.results[0].formatted_address; //complete formatted address

            let premise, neighborhood, sublocality, city, state, country, pincode;

            for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                        case "locality":
                            city = response.results[0].address_components[i].long_name;
                            break;
                        case "administrative_area_level_1":
                            state = response.results[0].address_components[i].long_name;
                            break;
                        case "country":
                            country = response.results[0].address_components[i].long_name;
                            break;
                        case "postal_code":
                            pincode = response.results[0].address_components[i].long_name;
                            break;
                        case "sublocality":
                            sublocality = response.results[0].address_components[i].long_name;
                            break;
                        case "neighborhood":
                            neighborhood = response.results[0].address_components[i].long_name;
                            break;
                        case "premise":
                            premise = response.results[0].address_components[i].long_name;
                            break;
                        default:
                    }
                }
            }

            let localityArray = [];
            if (neighborhood) {
                localityArray.push(neighborhood.trim());
            }
            if (sublocality) {
                localityArray.push(sublocality.trim());
            }

            let locality = localityArray.join(', ')

            setShopNo(premise);
            setLocality(locality);
            setCity(city);
            setState(state);
            setCountry(country);
            setPinCode(pincode);

            generateCompleteAddress();

        }, (error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (latitudeRedux && longitudeRedux) {
            handleChangeLocation(latitudeRedux, longitudeRedux)
        }
    }, [handleChangeLocation, latitudeRedux, longitudeRedux])

    useEffect(() => {
        if (shopNo || locality || city || state || country || pinCode) {
            generateCompleteAddress();
        }
    }, [shopNo, locality, city, state, country, pinCode]);

    const generateCompleteAddress = () => {
        let addressArray = [];

        if (shopNo) {
            addressArray.push(shopNo);
        }
        if (locality) {
            addressArray.push(locality);
        }
        if (city) {
            addressArray.push(city);
        }
        if (state) {
            addressArray.push(state);
        }
        if (country) {
            addressArray.push(country);
        }
        if (pinCode) {
            addressArray.push(pinCode);
        }

        let finalAddress = addressArray.join(', ');
        //setCompleteAddress(finalAddress);
        getLatitudeLongitudeFromAddress(finalAddress)
    }

    const getLatitudeLongitudeFromAddress = (address) => {
        if (updateLocation) {
            Geocode.fromAddress(address).then((response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setDefaultLocation({ lat, lng })
            }, (error) => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        fetchPinCodes().then(response => {
            if (response.status === 200) {
                setPinCodesData(response.data);
                setPinCodes(formatValueLabel('id', 'name', response.data));
                if (pin) {
                    setPinCode(pin)
                }
            }
        });
    }, [])

    useEffect(() => {
        if (pinCodeId) {
            fetchCountries().then(response => {
                if (response.status === 200) {
                    setCountries(formatValueLabel('id', 'name', response.data));
                }
            });
        }
    }, [pinCodeId]);

    useEffect(() => {
        if (countryId) {
            fetchStates(countryId).then(response => {
                if (response.status === 200) {
                    setStates(formatValueLabel('id', 'name', response.data));
                }
            });
        }
    }, [countryId]);

    useEffect(() => {
        if (countryId && stateId) {
            fetchCities(countryId, stateId).then(response => {
                if (response.status === 200) {
                    setCities(formatValueLabel('id', 'name', response.data));
                }
            });
        }
    }, [countryId, stateId]);

    const setCountryStateCity = (pinCodeId) => {
        let filteredPinCodeData = pinCodesData?.filter((pinCode) => {
            return pinCode?.id === pinCodeId
        })[0];
        setCountryId(filteredPinCodeData?.country_id_id);
        setStateId(filteredPinCodeData?.state_id_id);
        setCityId(filteredPinCodeData?.city_id_id);
    }

    return (
        <div className="row">

            {(defaultLocation.lat && defaultLocation.lng) ? <div className="col-md-12 mb-3">
                <div className="card-body p-0">
                    <MapPicker defaultLocation={defaultLocation}
                        zoom={zoom}
                        mapTypeId="roadmap"
                        style={{ height: '250px' }}
                        onChangeLocation={handleChangeLocation}
                        // onChangeZoom={handleChangeZoom}
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} />
                </div>
            </div>
                :
                <div className="col-md-12 mb-3">
                    <div className="card-body p-0">
                        <Label label='Enable location to load the map' labelClassName="text-danger" />
                    </div>
                </div>}

            <div className="col-md-6 mb-3">
                <Input label="Full Name" name="full_name" required={true} type="text" className="form-control" placeholder="Enter full name" />
            </div>

            <div className="col-md-4 mb-3">
                <Input label="Shop No. / Building No." value={shopNo} onChange={
                    (event) => {
                        setShopNo(event.target.value)
                        setUpdateLocation(true)
                        getLatitudeLongitudeFromAddress()
                    }
                } name="building_number" required={true} type="text" className="form-control" placeholder="Enter shop no. / building no." />
            </div>
            <div className="col-md-4 mb-3">
                <Input label="Locality" value={locality} onChange={
                    (event) => {
                        setLocality(event.target.value)
                        setUpdateLocation(true)
                        getLatitudeLongitudeFromAddress()
                    }
                } name="locality" required={true} type="text" className="form-control" placeholder="Enter locality" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="Pin Code" options={pinCodes} value={pinCodeId} onChange={(event) => {
                    setPinCode(event.label);
                    setPinCodeId(event.value);
                    setUpdateLocation(true);
                    setCountryStateCity(event.value);
                    getLatitudeLongitudeFromAddress();
                }} name="pincode" required={true} className="form-control" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="Country" disabled={true} options={countries} value={countryId} onChange={(event) => {
                    setCountry(event.label);
                    setCountryId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="country_id" required={true} className="form-control" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="State" disabled={true} options={states} value={stateId} onChange={(event) => {
                    setState(event.label);
                    setStateId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="state_id" required={true} className="form-control" />
            </div>
            <div className="col-md-4 mb-3">
                <SearchDropDown label="City" disabled={true} options={cities} value={cityId} onChange={(event) => {
                    setCity(event.label);
                    setCityId(event.value);
                    setUpdateLocation(true);
                    getLatitudeLongitudeFromAddress();
                }} name="city_id" required={true} className="form-control" />
            </div>
            {/* <div className="row">
                    <div className="col-md-12">
                        <Input label="Complete Address" value={completeAddress} disabled={true} required={false} type="text" className="form-control" placeholder="Enter your shop address here" />
                    </div>
                </div> */}
            <Input type="hidden" name="latitude" value={defaultLocation.lat} />
            <Input type="hidden" name="longitude" value={defaultLocation.lng} />

            <Input type="hidden" name="city_id" value={cityId} />
            <Input type="hidden" name="state_id" value={stateId} />
            <Input type="hidden" name="country_id" value={countryId} />
        </div >
    )
}

export default AddressPicker;