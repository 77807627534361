import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const NavBar = () => {

    const shop = useSelector((state) => state?.shop);
    const { slug } = useParams();

    return (
        <div className="border-bottom border-top">
            <nav className="navbar navbar-expand-lg navbar-light navbar-default p-0">
                <div className="container px-0">
                    <div className="offcanvas offcanvas-start p-4 p-lg-0" id="navbar-default">
                        <div className="d-flex justify-content-between align-items-center mb-2 d-block d-lg-none">
                            <Link to={shop?.slug ? `/${shop?.slug}` : '/'} className='navbar-brand'>
                                <img className='top-logo' src={shop?.logo_url || "/assets/images/logo.svg"} alt="logo" />
                            </Link>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                                <i className="feather-icon icon-x"></i>
                            </button>
                        </div>
                        <div className="d-lg-none d-block mb-3">
                            <button type="button" className="btn  btn-outline-gray-400 text-muted w-100 " data-bs-toggle="modal"
                                data-bs-target="#locationModal">
                                <i className="feather-icon icon-map-pin me-2"></i>Pick Location
                            </button>
                        </div>

                        {/* Nav Bar for Mobile */}
                        <div className="d-block d-lg-none">
                            <ul className="navbar-nav ">
                                <li className="nav-item ">
                                    <Link className="nav-link" to={(slug && shop?.slug === slug) ? `/${slug}` : '/'} >Home</Link>
                                </li>

                                <li className="nav-item ">
                                    <a className="nav-link dropdown-toggle" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Categories</a>
                                    <div className="collapse mt-2" id="collapseExample">
                                        <div className="card card-body">
                                            <ul className="mb-0 list-unstyled">
                                                {shop?.header_data?.categories?.map((category) => {
                                                    return <li><a className="dropdown-item" href="index-2.html">{category?.name}</a></li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to={(slug && shop?.slug === slug) ? `/${slug}/my-cart` : '/my-cart'} >My Cart</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={(slug && shop?.slug === slug) ? `/${slug}/my-wish-list` : '/my-wish-list'} >My Wishlist</Link>
                                </li>
                            </ul>
                        </div>


                        {/* Nav Bar for Desktop */}
                        <div className="d-none d-lg-block">
                            <ul className="navbar-nav ">
                                <li className="nav-item ">
                                    <Link className="nav-link" to={(slug && shop?.slug === slug) ? `/${slug}` : '/'} >Home</Link>
                                </li>
                                {shop?.header_data?.categories && <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Categories</a>
                                    <ul className="dropdown-menu">
                                        {shop?.header_data?.categories?.map((category) => {
                                            return <li className={`${category?.sub_categories.length > 0 ? 'dropdown-submenu dropend' : ''}`}>
                                                <a className={`dropdown-item ${category?.sub_categories?.length > 0 ? 'dropdown-list-group-item dropdown-toggle' : ''}`} href="index-2.html">{category?.name}</a>
                                                {category?.sub_categories?.length > 0 && <ul class="dropdown-menu">
                                                    {category?.sub_categories?.map((sub_category) => {
                                                        return <li><a class="dropdown-item" href="pages/account-orders.html">{sub_category?.name}</a></li>
                                                    })}
                                                </ul>}
                                            </li>
                                        })}
                                    </ul>
                                </li>}
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;