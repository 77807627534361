import { ActionTypes } from "../constants/actionTypes"

export const setUser = (user) => {
    return {
        type: ActionTypes.SET_USER,
        payload: user
    }
}

export const setCart = (products) => {
    return {
        type: ActionTypes.SET_CART,
        payload: products
    }
}

export const setWishlist = (products) => {
    return {
        type: ActionTypes.SET_WISHLIST,
        payload: products
    }
}

export const logoutUser = (user) => {
    return {
        type: ActionTypes.LOGOUT_USER,
        payload: user
    }
}