import apiService from "./api";

export const authMarketPlaceUser = async (id_token, country_code, phone) => {
    const response = await apiService.post("/shop_users/auth_user", { id_token, country_code, phone });
    return response.data;
}

export const authShopUser = async (id_token, shopId, country_code, phone) => {
    const response = await apiService.post(`/shop_users/auth_user?shop_id=${shopId}`, { id_token, country_code, phone });
    return response.data;
}