import Counter from "../Common/Counter";
import Currency from 'react-currency-formatter';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, deleteFromCart, getMyCart } from "../../api/settingsApi";
import { toast } from "react-toastify";
import { setLoaderView } from "../../redux/actions/settingActions";
import { Link } from "react-router-dom";
import { setOrderSummary } from "../../redux/actions/productActions";

const ProductCard = ({ product, setProducts }) => {

    const shop = useSelector((state) => state?.shop);
    const dispatch = useDispatch();

    const updateProductQuantity = (quantity) => {
        dispatch(setLoaderView(true));
        addToCart(quantity, product?.product_uuid).then(response => {
            if (response.status === 200) {
                getMyCart().then(response => {
                    setProducts(response?.data.rows);
                    dispatch(setOrderSummary({
                        deliveryCharges: response.data?.delivery_charges,
                        discountAmount: response.data?.discount_amount,
                        netAmount: response.data?.net_amount,
                        gst: response.data?.gst,
                        totalAmount: response.data?.total_amount,
                        totalGross: response.data?.total_gross,
                        deliveryTimeperiod: response.data?.delivery_timeperiod,
                        pickupTimeperiod: response.data?.pickup_timeperiod
                    }));
                    dispatch(setLoaderView(false));
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    toast.error(error.message)
                });
            } else {
                dispatch(setLoaderView(false));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    };

    const deleteItem = () => {
        dispatch(setLoaderView(true));
        deleteFromCart(product?.uuid).then(response => {
            if (response.status === 200) {
                toast.success(response.message)
                getMyCart().then(response => {
                    setProducts(response?.data?.rows);
                    dispatch(setOrderSummary({
                        deliveryCharges: response.data?.delivery_charges,
                        discountAmount: response.data?.discount_amount,
                        netAmount: response.data?.net_amount,
                        gst: response.data?.gst,
                        totalAmount: response.data?.total_amount,
                        totalGross: response.data?.total_gross,
                        deliveryTimeperiod: response.data?.delivery_timeperiod,
                        pickupTimeperiod: response.data?.pickup_timeperiod
                    }));
                    dispatch(setLoaderView(false));
                }).catch((error) => {
                    dispatch(setLoaderView(false));
                    toast.error(error.message)
                });
            } else {
                dispatch(setLoaderView(false));
            }
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        });
    }

    return (
        <div className="container mb-6">
            <div className="row justify-content-center">
                <div className="card col-12 p-6">
                    <div className="row g-4 align-items-center">
                        <div className="col-2 col-sm-3 col-md-2">
                            <img src={product?.file_url || `/assets/images/no_product_image.png`} alt="" className={`order-summary-image border rounded ${shop?.is_shop_open ? '' : 'disable-image'}`} />
                        </div>

                        <div className="col-5 col-sm-9 col-md-5 ps-sm-6 ps-6 ps-md-0">
                            <h3 className="mb-1">{product?.variant_name || product?.name}</h3>
                            <div>
                                <small className="text-warning">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-half"></i>
                                </small>
                                <Link to="/" className="ms-2">(30 reviews)</Link>
                            </div>
                            {product?.discount_amount ? <div className="product-price float-left m-0">
                                <span className="current-price-small text-primary">
                                    <Currency pattern="!##,##,###." quantity={product?.net_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                </span>
                                <span>
                                    <span className="save-price-small text-warning">{product?.discount_percentage}% Off</span>
                                    <span className="old-price-small text-secondary">
                                        <Currency pattern="!##,##,###." quantity={product?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                    </span>
                                </span>
                            </div>
                                :
                                <div className="product-price text-primary float-left m-0">
                                    <span className="current-price-small text-brand">
                                        <Currency pattern="!##,##,###." quantity={product?.gross_amount || 0} currency={shop?.currency_abbreviation || 'INR'} />
                                    </span>
                                </div>
                            }
                            {/* {product?.variant_size && <div className="d-flex">
                                <strong className="me-2">Size: </strong>
                                <p className="m-0">{product?.variant_size}</p>
                            </div>}
                            {product?.variant_color && <div className="d-flex">
                                <strong className="me-2">Color:</strong>
                                <div className="color-available" style={{ backgroundColor: product?.variant_color }} />
                            </div>} */}
                        </div>

                        <div className="col-2 col-sm-4 col-md-2 text-right">
                            <Counter value={product?.quantity} setQuantity={updateProductQuantity} />
                        </div>

                        <div className="col-2 col-sm-4 col-md-2 text-right">
                            <b><Currency pattern="!##,##,###." quantity={product?.unit_total_amount || 0} currency={shop?.currency_abbreviation || 'INR'} /></b>
                        </div>

                        <div className="col-1 col-sm-4 col-md-1 text-right">
                            <i onClick={deleteItem} className="bi bi-trash text-danger fs-4 cursor-pointer"></i>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard;