import TopBar from "./TopBar";
import { getShopConfiguration } from "../../../api/settingsApi";
import { setLoaderView, setShopDetail } from "../../../redux/actions/settingActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setProducts } from "../../../redux/actions/productActions";
import NavBar from "./NavBar";

const Header = () => {

    const { slug } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (slug) {
            dispatch(setLoaderView(true));
            getShopConfiguration(slug).then((response) => {
                if (response.status === 200) {
                    dispatch(setShopDetail(response.data));
                } else {
                    dispatch(setShopDetail(null));
                    dispatch(setProducts([]));
                }
                dispatch(setLoaderView(false));
            }).catch(() => {
                dispatch(setShopDetail({}));
                dispatch(setProducts([]));
                dispatch(setLoaderView(false));
            })
        } else {
            dispatch(setShopDetail(null));
            dispatch(setProducts([]));
        }
    }, [slug]);

    return (
        <header className="header-area header-style-1 header-height-2">
            <TopBar />
            <NavBar />
        </header>
    );
}

export default Header;