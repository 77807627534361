import { useEffect } from "react";

const AddressCard = ({ address, index, changeDefaultAddress, selectedAddress, setSelectedAddress, setSelectedAddressName }) => {

    useEffect(() => {
        if (address?.is_default) {
            setSelectedAddress(address?.uuid);
            setSelectedAddressName(address?.full_name);
        }
    }, [address]);

    return (
        <div className="col-lg-6 col-xxl-6 col-12 mb-6" onClick={() => {
            setSelectedAddress(address?.uuid);
            setSelectedAddressName(address?.full_name);
        }}>
            <div className="card">
                <div className={`card-body cursor-pointer ${selectedAddress === address.uuid ? 'selected-card rounded' : ''} `}>
                    <div className="form-check mb-3">
                        <input className="form-check-input cursor-pointer" type="radio" onClick={() => {
                            setSelectedAddress(address.uuid);
                            setSelectedAddressName(address?.full_name);
                        }} name="address" id={`address_${index}`} checked={address?.uuid === selectedAddress} />
                        <label className="form-check-label text-dark fw-bold cursor-pointer" for={`address_${index}`}>{address?.full_name}</label>
                    </div>

                    <div className="d-flex">
                        <i className='bi bi-geo-alt-fill me-2'></i>
                        <div>
                            <div className="d-flex">
                                <p className="m-0">{address?.building_number}</p>
                                <p className="m-0">, {address?.locality}</p>
                                <p className="m-0">, {address?.city_name}</p>
                            </div>
                            <div className="d-flex">
                                <p className="m-0">{address?.state_name}</p>
                                <p className="m-0">, {address?.country_name}</p>
                            </div>

                            <p className="m-0">{address?.pincode}</p>
                        </div>
                    </div>

                    <div className="d-flex mt-2">
                        <i className='bi bi-telephone-fill me-2'></i>
                        <p className="m-0">{`${address.country_code}-${address.phone_number}`}</p>
                    </div>

                    {/* <div className="mt-4 d-flex justify-content-between">
                        <div>
                            <a href="#" className="text-inherit">Edit </a>
                            <a href="#" className="text-danger ms-3" data-bs-toggle="modal" data-bs-target="#deleteModal">Delete</a>
                        </div>
                        {!address?.is_default && <div onClick={() => changeDefaultAddress(address?.uuid)} className="text-inherit cursor-pointer">Make default address</div>}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AddressCard;