import { ActionTypes } from "../constants/actionTypes"

export const shopReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_SHOP_DETAIL:
            return payload;
        default:
            return state;
    }
}

export const pinReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PIN:
            return payload;
        default:
            return state;
    }
}

export const latitudeReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_LATITUDE:
            return payload;
        default:
            return state;
    }
}

export const longitudeReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_LONGITUDE:
            return payload;
        default:
            return state;
    }
}

export const loaderReducer = (state = false, { type, payload }) => {
    switch (type) {
        case ActionTypes.SHOW_HIDE_LOADER:
            return payload;
        default:
            return state;
    }
}

export const bannersReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_BANNERS:
            return payload;
        default:
            return state;
    }
}