import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLatitude, setLongitude, setPIN } from "../../redux/actions/settingActions";
import { useEffect } from "react";
import Geocode from "react-geocode";

const PinPicker = () => {

    const dispatch = useDispatch();
    const pin = useSelector((state) => state?.pin);

    const [pinCode, setPinCode] = useState(pin);

    const submitPIN = (event) => {
        event.preventDefault();
        dispatch(setPIN(pinCode));
    }

    useEffect(() => {
        setPinCode(pin);
    }, [pin]);

    const setLatitudeLongitudeFromPIN = () => {
        Geocode.fromAddress(pinCode).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            dispatch(setLatitude(lat));
            dispatch(setLongitude(lng));
        }, (error) => {
            console.error(error);
        });
    }

    return (
        <form onSubmit={submitPIN}>

            <div class="card border-0 shadow">
                <div class="card-body p-6">
                    <div class="mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-map-pin text-danger">
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <h1 class="mt-3 mb-0 h4">Let's find local online stores near you</h1>
                        <small>Enter PIN code below to search nearby stores</small>
                    </div>
                    <div class="row g-3">
                        <div class="col">
                            <label for="postcod" class="visually-hidden">Postcode</label>
                            <input type="number" value={pinCode} onChange={(event) => setPinCode(event.target.value)} className="form-control" placeholder="Enter your PIN" />
                        </div>
                        <div class="col-auto">
                            <button type="submit" onClick={setLatitudeLongitudeFromPIN} className="btn btn-primary" data-bs-dismiss="modal">Set PIN</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default PinPicker;