import { ActionTypes } from "../constants/actionTypes"

export const setProducts = (products) => {
    return {
        type: ActionTypes.SET_PRODUCTS,
        payload: products
    }
}

export const setOrderSummary = (order) => {
    return {
        type: ActionTypes.SET_ORDER_SUMMARY,
        payload: order
    }
}