import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchProducts } from '../../../api/productsApi';
import { getBanners, qrScanCount } from '../../../api/settingsApi';
import { setProducts } from '../../../redux/actions/productActions';
import { setBanners, setLoaderView } from '../../../redux/actions/settingActions';
import Product from '../../Products/Product';
import Slider from './Slider';
import Carousel from '../MarketPlace/MarketPlaceSections/Carousel';
import TopMessage from '../../Common/Header/TopMessage';

const ShopProducts = () => {

    const dispatch = useDispatch();
    const { slug } = useParams();

    const shop = useSelector((state) => state?.shop);
    const products = useSelector((state) => state?.products);
    const banners = useSelector((state) => state?.banners);

    const loadProducts = () => {
        dispatch(setLoaderView(true));
        fetchProducts(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setProducts(response.data));
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        })
    }

    const loadBanners = () => {
        dispatch(setLoaderView(true));
        getBanners(shop?.uuid).then((response) => {
            if (response.status === 200) {
                dispatch(setBanners(response.data));
            }
            dispatch(setLoaderView(false));
        }).catch((error) => {
            dispatch(setLoaderView(false));
            toast.error(error.message)
        })
    }

    useEffect(() => {
        if (shop?.uuid) {
            loadBanners();
            loadProducts();
        } else {
            dispatch(setProducts([]));
            dispatch(setBanners([]));
        }
    }, [shop, slug]);

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const qrId = params.get('qr_code');
        if (qrId) {
            qrScanCount(qrId);
        }
        window.history.replaceState({}, document.title, window.location.pathname);
    }, [])

    return (

        <main>
            {shop?.is_shop_open === false && <TopMessage message={`${shop?.name} is currently closed and not accepting orders!`} />}
            <section>
                {/* {banners.length > 0 && <Slider banners={banners} />} */}
                {banners.length > 0 && <Carousel banners={banners} />}
                <div className="container">
                    <div className="row g-4 mt-1 mb-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
                        {products?.map((product, index) => {
                            return <Product key={index} product={product} />
                        })}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ShopProducts;