import { ActionTypes } from "../constants/actionTypes"

export const setShopCategories = (categories) => {
    return {
        type: ActionTypes.SET_SHOP_CATEGORIES,
        payload: categories
    }
}

export const setNearByShops = (shops) => {
    return {
        type: ActionTypes.SET_NEAR_BY_SHOPS,
        payload: shops
    }
}

export const setSearchedShops = (shops) => {
    return {
        type: ActionTypes.SET_SEARCHED_SHOPS,
        payload: shops
    }
}

export const setSearchedProducts = (products) => {
    return {
        type: ActionTypes.SET_SEARCHED_PRODUCTS,
        payload: products
    }
}