import apiService from "./api";

export const fetchShopCategories = async () => {
    const response = await apiService.get(`market/get_all_shop_categories`);
    return response?.data;
}

export const fetchNearByShops = async (latitide, longitude) => {
    const response = await apiService.get(`market/get_nearby_shops?latitude=${latitide}&longitude=${longitude}`);
    return response?.data;
}

export const searchShopsOrProducts = async (searchString) => {
    const response = await apiService.get(`market/search_marketplace?search_string=${searchString}`);
    return response?.data;
}