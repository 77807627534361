import apiService from "./api";
import securedApiService from "./securedApi";

export const getShopConfiguration = async (slug) => {
    const response = await apiService.post("/shop_users/get_shop_data", { slug });
    return response?.data;
}

export const getBanners = async (shopId) => {
    const response = await apiService.get(`/shop_users/get_main_page_banner?shop_id=${shopId}`);
    return response?.data;
}

export const getAddresses = async () => {
    const response = await securedApiService.get("/shop_users/get_addresses");
    return response?.data;
}

export const addNewAddress = async (formData) => {
    const response = await securedApiService.post("/shop_users/add_address", formData);
    return response?.data;
}

export const upadateDefaultAddress = async (addressId) => {
    const response = await securedApiService.put(`/shop_users/update_address/${addressId}`, { is_default: true });
    return response?.data;
}

export const fetchCountries = async () => {
    const response = await apiService.get("/master/get_country_data");
    return response?.data;
}

export const fetchStates = async () => {
    const response = await apiService.get("/master/get_state_data");
    return response?.data;
}

export const fetchCities = async () => {
    const response = await apiService.get("/master/get_city_data");
    return response?.data;
}

export const fetchPinCodes = async () => {
    const response = await apiService.get("/master/get_pincode_data");
    return response?.data;
}


//ORDERS
export const getMyOrders = async () => {
    const response = await securedApiService.get("/shop_orders/get_all_orders");
    return response?.data;
}

export const getOrderSummary = async (shopId, productId, quantity) => {
    const response = await securedApiService.get(`/shop_orders/buy_now_data/${productId}?shop_id=${shopId}&quantity=${quantity}`);
    return response?.data;
}


//CART
export const getMyCart = async () => {
    const response = await securedApiService.get(`/shop_orders/get_cart_data`);
    return response?.data;
}

export const addToCart = async (quantity, product_id) => {
    const response = await securedApiService.post(`/shop_orders/add_to_cart`, { quantity, product_id });
    return response?.data;
}

export const deleteFromCart = async (product_id) => {
    const response = await securedApiService.delete(`/shop_orders/delete_cart_item/${product_id}`);
    return response?.data;
}





// WISHLIST
export const getMyWishList = async () => {
    const response = await securedApiService.get(`/shop_orders/get_wishlist`);
    return response?.data;
}

export const addToWishList = async (product_id) => {
    const response = await securedApiService.post(`/shop_orders/add_to_wishlist`, { product_id });
    return response?.data;
}

export const deleteFromWishList = async (product_id) => {
    const response = await securedApiService.delete(`/shop_orders/delete_wishlist_item/${product_id}`);
    return response?.data;
}


// QR Count
export const qrScanCount = async (qrCodeId) => {
    const response = await apiService.get(`/shop_users/shop_qr_scan?qr_code=${qrCodeId}`);
    return response?.data;
}